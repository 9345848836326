<template>
     <!-- Main modal -->
     <transition
          enter-from-class="scale-0"
          enter-active-class="ease-in transition-medium"
          enter-to-class="scale-1"
          leave-from-class="scale-1"
          leave-active-class="ease-out transition-medium"
          leave-to-class="scale-0"
     >
          <div
               v-if="isPaymentCheckout"
               id="payment-checkout-modal"
               tabindex="-1"
               aria-hidden="true"
               class="overflow-y-hidden overflow-x-hidden fixed z-100 w-full inset-0 max-h-full transform ease-in-out transition-all duration-300 self-center m-auto flex justify-center items-center"
          >
               <div class="relative px-3 py-3 sm:py-4 sm:px-6 w-full max-w-2xl max-h-full tracking-wider m-auto">
                    <!-- Modal content -->
                    <div class="relative bg-white rounded-lg shadow font-roboto">
                         <button
                              @click="closeModal()"
                              type="button"
                              class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                              data-modal-hide="authentication-modal"
                              >
                              <img :src="baseUrl.img+'/assets/img/modal/exclude.svg'" alt="" />
                              <span class="sr-only">Close modal</span>
                         </button>
                         <div class="bg-gradient-page p-5 flex flex-col w-full h-max text-white rounded-t-lg">
                              <div class="flex flex-row justify-between pr-5">
                                   <p class="font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl capitalize">{{ actionType == 'credit card' ? 'Credit card details' : '' }}</p>
                              </div>
                         </div>
                         <div class="px-5 py-5 flex flex-col w-full h-max overflow-y-auto" style="max-height: calc(100vh - 160px);">
                              <!-- <OnlineBanking
                                   v-if="paymentMethod == 0 && isActionAccessible('dp_bank_button')"
                                   :coins="coin"
                                   :setPaymentSuccessCoins="setPaymentSuccessCoins"
                                   :setLoading="setLoading"
                                   v-model:limitReachValue="limitReachValue"
                                   v-model:dailyLimit="dailyLimit"
                                   :closeModal="closeModal"
                              /> -->
                              <CreditCard
                                   v-if="paymentMethod == 1 && isActionAccessible('dp_card_button')"
                                   :coins="coin"
                                   :setPaymentSuccessCoins="setPaymentSuccessCoins"
                                   :setLoading="setLoading"
                                   v-model:limitReachValue="limitReachValue"
                                   v-model:dailyLimit="dailyLimit"
                                   :closeModal="closeModal"
                              />
                         </div>
                    </div>
               </div>
          </div>
     </transition>
     <div
          v-if="isPaymentCheckout"
          class="fixed inset-0 z-60"
          style="background: rgba(0, 0, 0, 0.75);"
     ></div>
     <loading-overlay :isLoading="loading" />
</template>

<script>
import { onMounted, onUnmounted, ref, reactive, computed, watch } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";
import CreditCard from "./component/credit-card.vue";
import { useFormatter } from "composables/use-formatter.js";
import {
  limitReach as limitReachAPI
} from "api/auth.js";
import { authStore } from "store/auth.js";
import _ from "underscore";
import device from 'middleware/device.js';
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { useBank } from "composables/use-bank.js";
import { cardStore } from "store/cards.js";

export default {
     props: [],
     components: {
        CreditCard,
        LoadingOverlay
     },
     setup() {
          const isDev = import.meta.env.VITE_ENV;

          const { isActionAccessible } = useBank();

          const { money } = useFormatter();
          const { isIOS } = device();

          const useUtilitiesStore = utilitiesStore();
          const useAuthStore = authStore();
          const useCardStore = cardStore();

          const isPaymentCheckout = ref(false);

          const paymentMethod = ref(0);

          const coin = ref(null);

          const actionType = ref('');

          const paymentSuccessCoins = reactive({
               gold_coins: 0,
               silver_coins: 0,
          });

          const loading = ref(false);

          const limitReachValue = ref(0);
          const dailyLimit = ref(0);

          const closeModal = () => {
               isPaymentCheckout.value = false;
               useUtilitiesStore.enableScroll();
          };

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const bankStatus = computed(() => {
               return useCardStore.bankStatus;
          });

          const loadingScreen = computed(() => {
               return useUtilitiesStore.loading;
          });

          const setLoading = (data) => {
               loading.value = data;
          };

          async function setPaymentSuccessCoins(res) {
               paymentSuccessCoins.gold_coins = money(res.gold_coins);
               paymentSuccessCoins.silver_coins = money(res.silver_coins);
          }

          onMounted(async () => {

               await limitReachAPI().then((res) => {
                    if (res.status) {
                         limitReachValue.value = Number(res.purchase_amount)
                    };
               });

               eventBus.on("open:add_bank_payment", (type) => {
                    useUtilitiesStore.disableScroll();
                    actionType.value = type;
                    paymentMethod.value = type == 'credit card' ? 1 : 0;
                    isPaymentCheckout.value = true
               });

               eventBus.on("close:add_bank_payment", () => {
                   useUtilitiesStore.enableScroll();
                   actionType.value = '';
                   paymentMethod.value = 0;
                   isPaymentCheckout.value = false;
              });
          });

          onUnmounted(() => {
               eventBus.off("open:add_bank_payment");
               eventBus.off("close:add_bank_payment");
          });

          watch(loadingScreen, (newVal) => {
               if (!newVal) {
                    if (isUser?.value?.meta) {
                         let filter = _.filter(isUser.value.meta, function (params) {
                              return params["meta_key"] == "daily_limit";
                         });
                         if (filter.length >= 1) {
                              const parseFilter = JSON.parse(filter[0].meta_value);
                              dailyLimit.value = Number(parseFilter.amount);
                         }
                    }
               }
          });

          watch(bankStatus.value, (newVal) => {
               if (newVal) {
                    paymentMethod.value = isActionAccessible('dp_card_button') ? 0 : 1;
               }
          });

          return {
               isPaymentCheckout,
               closeModal,
               baseUrl,
               paymentMethod,
               coin,
               money,
               setPaymentSuccessCoins,
               setLoading,
               isIOS,
               isDev,
               isActionAccessible,
               isUser,
               actionType
          };
     },
};
</script>