<template>

     <InertiaHead title="All Games"></InertiaHead>
     <div class="w-full bg-primary-2 relative mt-0">
          <div class="w-full h-full flex flex-col justify-start items-center md:items-start pb-8 md:pb-12 lg:pb-18 xl:pb-24">
               <div v-if="banner" class="w-full bg-primary-1">
                    <Carousel
                         :wrap-around="true"
                         :itemsToShow="0.9"
                         snapAlign="start"
                         :transition="500"
                         key="games-banner-carousel"
                         class="w-full games-banner-carousel"
                         :autoplay="2000"
                    >
                         <Slide
                              v-for="(slide, key) in banner"
                              :key="key"
                         >
                              <div class="carousel__item w-full cursor-pointer">
                                   <img @click="gotoPromotion(slide)" :src="baseUrl.img+'/'+slide.banner" alt="" srcset="" class="w-full h-auto cursor-pointer">
                              </div>
                         </Slide>

                         <template #addons>
                              <Navigation />
                         </template>
                    </Carousel>
               </div>
               <div class="w-full flex flex-col-reverse md:flex-col">
                    <div class="w-full px-0 md:px-24 lg:px-28 xl:px-32 2xl:px-32 md:-mt-14 flex justify-center items-center">
                         <div class="w-full 3xl:max-w-screen-3xl px-5 xs:px-8 sm:px-18 md:px-0">
                              <div class="relative w-full">
                                   <SearchGame
                                        :onSearch="onSearch"
                                        :provider="provider"
                                        :isDropdown="isDropdown"
                                        :onDropdown="(val) => {isDropdown = (val != null ? val : !isDropdown)}"
                                        :selectedDropdown="selectedDropdown"
                                        :onSelect="onSelect"
                                        :getSelectedDropwdown="getSelectedDropwdown"
                                        :hideDropdown="true"
                                   />
                              </div>
                         </div>
                    </div>
                    <div class="bg-primary-1 w-full flex justify-center px-0 md:px-24 lg:px-28 xl:px-32 z-10 md:mt-2 p-1">
                         <div class="rounded-none md:rounded-full w-full 3xl:max-w-screen-3xl h-11 bg-primary-1 shadow-lg flex flex-row items-center">
                              <CategoryGame
                                   :game_type="game_type"
                                   :isUser="isUser"
                                   :onSetActive="onSetActive"
                                   :active="active"
                                   :isExpand="isExpand"
                                   :onExpand="(val) => {isExpand = val}"
                              />
                              <SortGame
                                   :pagination="pagination"
                                   :sortByOpen="sortByOpen"
                                   :onSortByOpen="(val) => { sortByOpen = (val != null ? val : !sortByOpen)}"
                                   :onSort="onSort"
                              />
                         </div>
                    </div>
               </div>
               <div class="mt-3 w-full px-0 md:px-24 lg:px-28 xl:px-32 bg-primary-2 pb-6 md:pb-12 lg:pb-18 xl:pb-28">
                    <loading-overlay :isLoading="loading" />
                    <div
                         v-if="pagination.data != null && pagination.data.length != 0"
                         class="game-wrapper mt-3 w-full 3xl:max-w-screen-3xl h-full grid-cols-2 grid-rows-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 grid gap-3 md:gap-5 relative px-5 xs:px-8 sm:px-18 md:px-0 mx-auto"
                    >
                         <CardGame
                              :pagination="pagination"
                              :onStar="onStar"
                              :onLaunch="onLaunch"
                              :isUser="isUser"
                              :provider="provider"
                         />
                    </div>
                    <div
                         v-else
                         class="px-8 sm:px-18 md:px-0 w-full py-20 md:py-48 text-white"
                    >
                         <h1 v-if="selfExclusion == null" class="text-primary-1 text-center w-full font-roboto tracking-wide text-xs md:text-xs lg:text-sm xl:text-lg">
                              No data has been found!
                         </h1>
                         <SelfExclusionAlert :selfExclusion="selfExclusion" v-else />
                    </div>

                    <div
                         v-if="pagination.data != null && pagination.data.length > 10 && pagination.totalPage != 1 && pagination.totalPage != pagination.page"
                         class="w-full flex justify-center items-center mt-14"
                    >
                         <div
                              @click="seeMore"
                              class="rounded-full font-roboto font-bold tracking-wider px-10 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg cursor-pointer"
                         >
                              See More
                         </div>
                    </div>
               </div>
               <Favorite v-if="isUser" page="games"/>
               <Recent v-if="isUser" page="games"/>
          </div>
     </div>
</template>
<script>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { gamesStore } from "store/games";
import { authStore } from "store/auth.js";
import _ from "underscore";
import { setFavorites as setFavoritesAPI } from "@/api/games";
import { gameLauncher as gameLauncherAPI } from "@/api/seamless";
import { useToaster } from "composables/use-toaster.js";
import { useFormatter } from "composables/use-formatter.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import { Head as InertiaHead } from "@inertiajs/vue3";
import { useRoute, useRouter } from "vue-router";
import { promoStore } from "store/promotion";
import { useScroll } from "composables/use-scroll.js";
import SelfExclusionAlert from "components/selfExclusionAlert/index.vue";
import { baseUrl } from "config/services.js";
import CardGame from "components/games/cards/index.vue";
import SortGame from "components/games/sortby/index.vue";
import CategoryGame from "components/games/category/index.vue";
import SearchGame from "components/games/search/index.vue";
import Favorite from "pages/home/components/favorite/index.vue";
import Recent from "pages/home/components/recent/index.vue";
import { utilitiesStore } from "store/utilities.js";
import { useKYC } from 'composables/use-kyc.js';

export default {
     components: {
          LoadingOverlay,
          Carousel,
          Slide,
          Navigation,
          InertiaHead,
          SelfExclusionAlert,
          CardGame,
          SortGame,
          CategoryGame,
          SearchGame,
          Favorite,
          Recent
     },
     setup() {
          const { open_kyc_game_launcher } = useKYC();
          const { money } = useFormatter();
          const route = useRoute();
          const router = useRouter();
          const { scrollTop } = useScroll();

          const banner = ref(null);
          const isExpand = ref(false);

          const isDropdown = ref(false);

          const pagination = reactive({
               prevPage: false,
               nextPage: true,
               data: null,
               loading: null,
               totalPage: 0,
               page: 1,
               itemsPerPage: 20,
               game_type: [],
               game_promo: 1,
               game_name: "",
               provider_id: [],
               sortType: "popularity",
          });

          const selfExclusion = ref(null);

          const game_type = ref(null);

          const dropdownManual = ref(null);
          const dropdownManualMobile = ref(null);
          const dropdownOpen = ref(false);
          const selectedDropdown = ref([]);

          const provider = ref([]);

          // all
          const active = ref("hot");

          const all_count = ref(0);

          const loading = ref(false);

          const sortByOpen = ref(false);

          const useAuthStore = authStore();
          const useGamesStore = gamesStore();
          const usePromoStore = promoStore();
          const useUtilitiesStore = utilitiesStore();

          let timer,
               timeoutVal = 1000;

          const gameFilter = computed(() => {
               const obj = Object.keys(useGamesStore.gameFilter);
               for (let index = 0; index < obj.length; index++) {
                    pagination[obj[index]] = useGamesStore.gameFilter[obj[index]]
               }

               active.value = useGamesStore.gameFilter['active']

               return useGamesStore.gameFilter;
          });

          const gamesData = computed(() => {
               pagination.data = useGamesStore.allGames?.data;
               return useGamesStore.allGames || null;
          });

          const groupData = computed(() => {
               if (useGamesStore.groups) {
                    if (route.path.split("/")[2] == "type") {
                         const filter = _.filter(
                              useGamesStore.groups,
                              (params) => {
                                   return (
                                        params["game_type"].toLowerCase() ==
                                        route.path.split("/")[3]
                                   );
                              }
                         );
                         if (filter.length != 0) {
                              active.value = filter[0]["game_type"];
                              pagination.game_type = [filter[0]["game_type"]];
                         }
                    }
               }
               if (useGamesStore.groups) {
                    game_type.value = useGamesStore.groups;
               }
               return useGamesStore.groups || null;
          });

          const providerData = computed(() => {
               if (useGamesStore.provider) {
                    const all = {
                         name: "Game Studios",
                         id: 0,
                    };

                    if (route.path.split("/")[2] == "provider") {
                         const name = route.path.split("/")[3].replaceAll("-", " ").toLowerCase()

                         const filter = _.filter(
                              useGamesStore.provider,
                              (params) => {
                                   return (
                                        params["name"].toLowerCase() == name
                                   );
                              }
                         );
                         if (filter.length != 0) {
                              selectedDropdown.value = [filter[0]["id"]];
                         } else {
                              selectedDropdown.value = [
                                   0,
                                   ..._.pluck(useGamesStore.provider, "id"),
                              ];
                         }
                    } else {
                         selectedDropdown.value = [
                              0,
                              ..._.pluck(useGamesStore.provider, "id"),
                         ];
                    }
                    provider.value = [...useGamesStore.provider, all];
               }
               return useGamesStore.provider || null;
          });

          const favoritesData = computed(() => {
               return useGamesStore.allFavorites || null;
          });

          const recentData = computed(() => {
               return useGamesStore.allRecent || null;
          });

          const errorImages = ref([]);

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const { errorToast } = useToaster();

          const bannerData = computed(() => {
               if (usePromoStore.banner) {
                    banner.value = usePromoStore.banner;
               }
               return usePromoStore.banner || null;
          });

          const preloading = computed(() => {
               return useUtilitiesStore.loading;
          });

          const seeMore = async () => {
               loading.value = true;
               pagination.page = pagination.page + 1;

               if(active.value != "favorite" && active.value != "recent") {
                    const params = setParameters(pagination);
                    useGamesStore.setGameFilter(params, active.value);
                    await useGamesStore.getGames(params, "games");
               } else {
                    if (active.value == "favorite") {
                         const params = setParameters(
                              pagination,
                              "favorite"
                         );
                         useGamesStore.setGameFilter(params, active.value);
                         await useGamesStore.getFavorites(params, "games");
                    } else {
                         const params = setParameters(
                              pagination,
                              "recent"
                         );
                         useGamesStore.setGameFilter(params, active.value);
                         await useGamesStore.getRecent(params, "games");
                    }
               }

               loading.value = false;
          };

          const onImageError = (e) => {
               let temp = errorImages.value;
               temp.push(parseInt(e.target.alt));

               const uniq = _.uniq(temp);

               errorImages.value = uniq;

               e.target.src = "assets/img/games/default.png";
          };

          const onSetActive = async (name) => {
               if (active.value != name) {
                    active.value = name;

                    loading.value = true;
                    pagination.page = 1;

                    if (active.value == "hot") {
                         pagination.game_promo = 1;
                         pagination.sortType = 'popularity';
                    } else {
                         pagination.game_promo = 0;
                    }

                    if (active.value == "all" || active.value == "hot") {
                         pagination.game_type = [];
                    } else {
                         pagination.game_type = [active.value];
                    }

                    if (
                         active.value == "recent" ||
                         active.value == "favorite"
                    ) {
                         if (active.value == "favorite") {
                              const params = setParameters(
                                   pagination,
                                   "favorite"
                              );
                              useGamesStore.setGameFilter(params, active.value);
                              await useGamesStore.getFavorites(
                                   params,
                                   "games",
                                   true
                              );
                              loading.value = false;
                         } else {
                              const params = setParameters(
                                   pagination,
                                   "recent"
                              );
                              useGamesStore.setGameFilter(params, active.value);
                              await useGamesStore.getRecent(
                                   params,
                                   "games",
                                   true
                              );
                              loading.value = false;
                         }
                         return;
                    }

                    const params = setParameters(pagination);
                    useGamesStore.setGameFilter(params, active.value);
                    await useGamesStore.getGames(params, "games", true);

                    loading.value = false;
               }
          };

          const onSearch = (query) => {
               window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
               timer = window.setTimeout(async () => {
                    loading.value = true;
                    pagination.page = 1;
                    pagination.game_name = query.toLowerCase();

                    if (
                         active.value == "recent" ||
                         active.value == "favorite"
                    ) {
                         if (active.value == "favorite") {
                              const params = setParameters(
                                   pagination,
                                   "favorite"
                              );
                              useGamesStore.setGameFilter(params, active.value);
                              await useGamesStore.getFavorites(
                                   params,
                                   "games",
                                   true
                              );
                              loading.value = false;
                         } else {
                              const params = setParameters(
                                   pagination,
                                   "recent"
                              );
                              useGamesStore.setGameFilter(params, active.value);
                              await useGamesStore.getRecent(
                                   params,
                                   "games",
                                   true
                              );
                              loading.value = false;
                         }
                         return;
                    }

                    const params = setParameters(pagination);
                    useGamesStore.setGameFilter(params, active.value);
                    await useGamesStore
                         .getGames(params, "games", true)
                         .catch(() => {
                              loading.value = false;
                         });

                    loading.value = false;
               }, timeoutVal);
          };

          const onStar = async (id, status) => {
               loading.value = true

               const params = { game_id: id };

               if (status == true) {
                    params["unset"] = 1;
               }

               await setFavoritesAPI(params);

               const parameters = setParameters(pagination, "favorite");
               if (active.value == "favorite") {
                    await useGamesStore.getFavorites(parameters, "games", true);
               } else {
                    if (isUser.value) {
                         await useGamesStore.getHeart();
                    }
               }

               const sliderParams = {
                    game_name: "",
                    game_promo: 0,
                    game_type: [],
                    provider_id: [],
                    page: 1,
                    itemsPerPage: 20,
                    sortType: "a-z"
               }

               sliderParams.provider_id = [..._.pluck(useGamesStore.provider, "id")];

               await useGamesStore.getFavoriteSlider(sliderParams, true);

               loading.value = false
          };

          const onLaunch = async (slug, type) => {
               if (isUser.value) {
                    if(open_kyc_game_launcher() == true) {
                         return;
                    };

                    const filter = _.filter(
                         providerData.value,
                         function (params) {
                              return params.id == slug.provider_id;
                         }
                    );

                    if(filter.length == 0) {
                        return errorToast("Provider Didn't Match!", "top-right");
                    };

                    await gameLauncherAPI(
                         { game_slug: slug.game_slug, type: type },
                         filter[0]["game_source"]
                    )
                         .then((res) => {
                              if (res.status) {
                                   if (
                                        /iPad|iPhone|iPod/.test(
                                             navigator.userAgent
                                        )
                                   ) {
                                        // alert("This is an iOS device.");
                                        setTimeout(() => {
                                            //  window.open(res.url, "_blank");
                                            useAuthStore.setActiveCoinType(type);
                                            useAuthStore.setActiveGameUrl(res.url);
                                            router.push({ name: 'play', params: { slug: slug.game_slug, id: slug.provider_id, launchcode: res.launch_code, type: type } });
                                        });
                                   } else {
                                    useAuthStore.setActiveCoinType(type);
                                    useAuthStore.setActiveGameUrl(res.url);
                                    router.push({ name: 'play', params: { slug: slug.game_slug, id: slug.provider_id, launchcode: res.launch_code, type: type } });
                                        // window.open(res.url, "_blank");
                                   }
                              }
                         })
                         .catch(() => {
                              errorToast("Something went wrong!", "top-right");
                         });
               } else {
                    router.push({ name: "login" });
               }
          };

          function onVisibleChange(e) {
               dropdownOpen.value = e;
          }

          function onDropdownTrigger(e, type) {
               if (type == "mobile") {
                    if (!dropdownManualMobile.value.visible) {
                         dropdownManualMobile.value.display();
                    } else {
                         dropdownManualMobile.value.close();
                    }
               } else {
                    if (!dropdownManual.value.visible) {
                         dropdownManual.value.display();
                    } else {
                         dropdownManual.value.close();
                    }
               }
          }

          function onCloseDropdown() {
               dropdownManual.value.close();
               dropdownManualMobile.value.close();
          }

          function getSelectedDropwdown(id) {
               if (selectedDropdown.value.length == 1) {
                    const filter = _.filter(
                         providerData.value,
                         function (params) {
                              return params["id"] == id;
                         }
                    );

                    return filter[0]["name"];
               } else {
                    return "Game Studios";
               }
          }

          const onSelect = async (id) => {
               isDropdown.value = false;
               loading.value = true;

               let data = selectedDropdown.value;
               if (id == 0) {
                    if (data.length == 0) {
                         data = [0, ..._.pluck(providerData.value, "id")];
                         selectedDropdown.value = data;
                         pagination.provider_id = data;
                    } else {
                         selectedDropdown.value = [];
                         pagination.provider_id = [];
                    }
               } else {
                    if (data.includes(id)) {
                         if (data.includes(0)) {
                              data.splice(data.indexOf(0), 1);
                         }
                         data.splice(data.indexOf(id), 1);
                         selectedDropdown.value = data;
                         pagination.provider_id = data;
                    } else {
                         data.push(id);
                         if (data.length == providerData.value.length) {
                              selectedDropdown.value = [0, ...data];
                         } else {
                              selectedDropdown.value = data;
                         }
                         pagination.provider_id = data;
                    }
               }

               pagination.page = 1;

               if (active.value == "recent" || active.value == "favorite") {
                    if (active.value == "favorite") {
                         const params = setParameters(pagination, "favorite");
                         useGamesStore.setGameFilter(params, active.value);
                         await useGamesStore.getFavorites(
                              params,
                              "games",
                              true
                         );
                         loading.value = false;
                    } else {
                         const params = setParameters(pagination, "recent");
                         useGamesStore.setGameFilter(params, active.value);
                         await useGamesStore.getRecent(params, "games", true);
                         loading.value = false;
                    }
                    return;
               }

               const params = setParameters(pagination);
               useGamesStore.setGameFilter(params, active.value);
               await useGamesStore.getGames(params, "games", true);
               loading.value = false;
          };

          const onSort = async (value) => {
               loading.value = true;
               pagination.sortType = value
               pagination.page = 1;

               sortByOpen.value = false;

               if (active.value == "recent" || active.value == "favorite") {
                    if (active.value == "favorite") {
                         const params = setParameters(pagination, "favorite");
                         useGamesStore.setGameFilter(params, active.value);
                         await useGamesStore.getFavorites(
                              params,
                              "games",
                              true
                         );
                         loading.value = false;
                    } else {
                         const params = setParameters(pagination, "recent");
                         useGamesStore.setGameFilter(params, active.value);
                         await useGamesStore.getRecent(params, "games", true);
                         loading.value = false;
                    }
                    return;
               }

               const params = setParameters(pagination);
               useGamesStore.setGameFilter(params, active.value);
               await useGamesStore.getGames(params, "games", true);
               loading.value = false;
          };

          const openLogin = () => {
               router.push({ name: "login" });
          };

          const setParameters = (data, game_tpye) => {
               const params = {};
               params["game_name"] = data?.game_name;
               params["game_promo"] = data?.game_promo;
               params["game_type"] =
                    game_tpye == "favorite" || game_tpye == "recent"
                         ? []
                         : data?.game_type;
               params["provider_id"] = data?.provider_id;

               params["page"] = data?.page;
               params["itemsPerPage"] = data?.itemsPerPage;
               params["sortType"] = data?.sortType;
               return params;
          };

          const gotoPromotion = (item) => {
               router.push({
                    path:
                         "/promotion/detail/" +
                         item.title.replaceAll(" ", "-") +
                         "-" +
                         item.title_highlight.replaceAll(" ", "-") +
                         "-" +
                         item.id,
               });
          };

          onMounted(async () => {
               if(!preloading.value) {
                    loading.value = true
               }
               scrollTop();

               if(useGamesStore.allGames != null || useGamesStore.allFavorites != null || useGamesStore.allRecent != null) {

                    if (gameFilter.value.provider_id) {
                         selectedDropdown.value = gameFilter.value.provider_id;
                    }

                    if (active.value == "all") {
                         pagination.data = useGamesStore.allGames['data'];

                         pagination.totalPage = useGamesStore.allGames["last_page"];
                    }

                    if (active.value == "favorite") {
                         pagination.data = useGamesStore.allFavorites['data'];

                         pagination.totalPage = useGamesStore.allFavorites["last_page"];
                    }

                    if (active.value == "recent") {
                         pagination.data = useGamesStore.allRecent['data'];;

                         pagination.totalPage = useGamesStore.allRecent["last_page"];
                    }

                    loading.value = false
                    return;
               }

               await usePromoStore.getPromotion({ published_at: "games" });

               await useGamesStore.getProvider();

               if (route.path.split("/")[2] == "provider") {
                    const name = route.path.split("/")[3].replaceAll("-", " ").toLowerCase()
                    const filter = _.filter(
                         providerData.value,
                         (params) => {
                              return (
                                   params["name"].toLowerCase() == name
                              );
                         }
                    );
                    if (filter.length != 0) {
                         pagination.provider_id = [filter[0]["id"]];
                    } else {
                         pagination.provider_id = [..._.pluck(providerData.value, "id")];
                    }
               } else {
                    pagination.provider_id = [..._.pluck(providerData.value, "id")];
               }

               await useGamesStore.getGameType();

               if (route.path.split("/")[2] == "type") {
                    const filter = _.filter(groupData.value, (params) => {
                         return (
                              params["game_type"].toLowerCase() ==
                              route.path.split("/")[3]
                         );
                    });
                    if (filter.length != 0) {
                         pagination.game_type = [filter[0]["game_type"]];
                    }
               }

               const params = setParameters(pagination);
               useGamesStore.setGameFilter(params, active.value);
               await useGamesStore.getGames(params, "games");

               if (isUser.value) {
                    await useGamesStore.getHeart();
               }

               loading.value = false

          });

          let x = 0;
          watch(providerData, (newVal) => {
               if (newVal) {
                    if (x == 0) {
                         const all = {
                              name: "Game Studios",
                              id: 0,
                         };

                         if (route.path.split("/")[2] == "provider") {
                              const name = route.path.split("/")[3].replaceAll("-", " ").toLowerCase()

                              const filter = _.filter(
                                   providerData.value,
                                   (params) => {
                                        return (
                                             params["name"].toLowerCase() == name
                                        );
                                   }
                              );
                              if (filter.length != 0) {
                                   selectedDropdown.value = [filter[0]["id"]];
                              } else {
                                   selectedDropdown.value = [
                                        0,
                                        ..._.pluck(newVal, "id"),
                                   ];
                              }
                         } else {
                              selectedDropdown.value = [
                                   0,
                                   ..._.pluck(newVal, "id"),
                              ];
                         }

                         provider.value = [...newVal, all];
                         x++;
                    }
               }
          });

          watch(gamesData, (newVal) => {
               if (newVal) {
                    let query = newVal["data"];

                    if(query) {
                         pagination.data = query;

                         if(active.value == 'hot') {
                              if(newVal["last_page"] < 3) {
                                   pagination.totalPage = newVal["last_page"];
                              } else {
                                   pagination.totalPage = 3;
                              }
                         } else {
                              pagination.totalPage = newVal["last_page"];
                         }

                         if (active.value == "all") {
                              all_count.value = newVal["total"];
                         }
                    } else {
                         selfExclusion.value = newVal
                    }
               }
          });

          watch(groupData, (newVal) => {
               if (newVal) {
                    game_type.value = newVal;
                    if (route.path.split("/")[2] == "type") {
                         const filter = _.filter(newVal, (params) => {
                              return (
                                   params["game_type"].toLowerCase() ==
                                   route.path.split("/")[3]
                              );
                         });
                         if (filter.length != 0) {
                              active.value = filter[0]["game_type"];
                              pagination.game_type = [filter[0]["game_type"]];
                         }
                    }
               }
          });

          watch(favoritesData, (newVal) => {
               if (newVal) {
                    const query = newVal["data"];

                    if(query) {
                         if (active.value == "favorite") {
                              pagination.data = query;

                              pagination.totalPage = newVal["last_page"];
                         }
                    } else {
                         selfExclusion.value = newVal
                    }
               }
          });

          watch(recentData, (newVal) => {
               if (newVal) {
                    const query = newVal["data"];

                    if(query) {
                         if (active.value == "recent") {
                              pagination.data = query;

                              pagination.totalPage = newVal["last_page"];
                         }
                    } else {
                         selfExclusion.value = newVal
                    }
               }
          });

          watch(gameFilter, (newVal) => {
               if (newVal) {
                    const obj = Object.keys(newVal);
                    for (let index = 0; index < obj.length; index++) {
                         pagination[obj[index]] = newVal[obj[index]]
                    }

                    active.value = newVal['active']
               }
          });

          watch(bannerData, (newVal) => {
               if (newVal) {
                    banner.value = newVal;
               }
          });

          return {
               isExpand,
               isDropdown,
               active,
               onSetActive,
               gamesData,
               pagination,
               seeMore,
               onImageError,
               isUser,
               favoritesData,
               recentData,
               game_type,
               onSearch,
               onStar,
               onLaunch,
               errorImages,
               provider,
               onDropdownTrigger,
               onCloseDropdown,
               dropdownManual,
               selectedDropdown,
               onSelect,
               dropdownOpen,
               onVisibleChange,
               dropdownManualMobile,
               openLogin,
               all_count,
               getSelectedDropwdown,
               money,
               loading,
               banner,
               selfExclusion,
               sortByOpen,
               onSort,
               baseUrl,
               gotoPromotion
          };
     },
};
</script>
