<template>
    <transition enter-from-class="scale-0" enter-active-class="ease-in transition-medium" enter-to-class="scale-1"
        leave-from-class="scale-1" leave-active-class="ease-out transition-medium" leave-to-class="scale-0">
        <div v-if="isOpenLaunchConfirmation" id="launchconfirmation-modal" tabindex="-1" aria-hidden="true"
            class="overflow-y-auto overflow-x-hidden fixed z-80 w-full inset-0 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 font-helveticaLight flex justify-center items-center">
            <div class="relative px-6 py-3 sm:py-4 sm:px-10 w-full max-w-lg max-h-full m-auto">
                <!-- Modal content -->
                <div class="relative bg-shade-1 rounded-xl shadow-xl">
                    <button @click="closeModal()" type="button"
                        class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                        data-modal-hide="launchconfirmation-modal">
                        <img :src="baseUrl.img + '/assets/img/modal/exclude.svg'" alt="" />
                        <span class="sr-only">Close modal</span>
                    </button>
                    <div
                        class="flex flex-col items-center justify-center py-6 lg:py-8 font-roboto text-shade-2 text-sm xs:text-base px-4 sm:px-6 lg:px-8 tracking-wide leading-tight">
                        <div class="pb-4 w-full text-center">
                            <h3 class="text-base xs:text-lg font-bold text-gray-900">
                                Do you wish to play this game?<br>
                                Warning! this will end your active session
                            </h3>
                        </div>
                        <div class="pb-2 w-full font-normal">
                            <div class="pt-2 xs:pt-6 text-center w-full font-helveticaLight">
                                <div class="px-2 flex w-full gap-4">
                                    <button @click="closeModal()"
                                        class="w-full text-primary-1 bg-gray-190 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold">
                                        Cancel
                                    </button>
                                    <button @click="onLaunch()"
                                        class="w-full text-primary-1 bg-secondary-3 px-4 py-3 xl:py-3.5 text-center leading-tight shadow-xl rounded-full font-bold">
                                        Yes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <div v-if="isOpenLaunchConfirmation" class="fixed inset-0 z-60" style="background-color: rgba(0, 0, 0, 0.3)"></div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
import { baseUrl } from "config/services.js";

export default {
    props: {
        onLaunchGame: {
            type: Function,
            required: true,
        },
        gameDetails: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const isOpenLaunchConfirmation = ref(false);
        const useUtilitiesStore = utilitiesStore();

        const closeModal = () => {
            isOpenLaunchConfirmation.value = false;
            useUtilitiesStore.enableScroll();
        };

        const onLaunch = () => {
            props.onLaunchGame(props.gameDetails, props.type);
        };

        onMounted(async () => {
            eventBus.on("open:launchconfirmation", () => {
                useUtilitiesStore.disableScroll();
                isOpenLaunchConfirmation.value = true
            });

            eventBus.on("close:launchconfirmation", () => {
                useUtilitiesStore.enableScroll();
                isOpenLaunchConfirmation.value = false
            });
        });

        onUnmounted(() => {
            eventBus.off("open:launchconfirmation");
            eventBus.off("close:launchconfirmation");
        });

        return {
            isOpenLaunchConfirmation,
            closeModal,
            baseUrl,
            onLaunch
        };
    },
};
</script>
