<template>
    <div id="apple-btn-area" class="bg-white w-full pt-5 md:pt-5 lg:pt-5 flex flex-col justify-center items-center">
        <apple-pay-button buttonstyle="black" type="pay" locale="en-US"></apple-pay-button>
    </div>
    <div v-if="loading" class="flex items-center justify-center w-full pt-10">
        <div class="flex flex-col items-center justify-center">
            <svg aria-hidden="true" class="w-8 h-8 text-black animate-spin fill-secondary-1" viewBox="0 0 100 101"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"></path>
                <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"></path>
            </svg>
            <p class="py-2">Please wait...</p>
        </div>
    </div>
</template>

<script>
import { onMounted, onActivated, ref, toRefs } from "vue";
import eventBus from "plugins/event.js";
import {
    logResponse as logResponseAPI,
    authorizeMerchant as authorizeMerchantAPI,
    payment as paymentAPI,
} from 'api/applepay.js'

export default {
    props: ["coins", "setLoading", "closeModal", "setPaymentSuccessCoins"],
    components: {
    },
    setup(props) {
        const loading = ref(false);

        const { coins } = toRefs(props);

        const sendResponse = async (data) => {

            // var xhr = new XMLHttpRequest;
            // var requestUrl = window.location.origin + '/api/apple-pay/log-response';

            // xhr.open('POST', requestUrl);

            // xhr.onload = function() {
            //     if (this.status >= 200 && this.status < 300) {
            //         // return console.log(xhr.response);
            //     } else {
            //     return reject({
            //         status: this.status,
            //         statusText: xhr.statusText
            //     });
            //     }
            // };

            // xhr.onerror = function() {
            //     return reject({
            //         status: this.status,
            //         statusText: xhr.statusText
            //     });
            // };

            // xhr.setRequestHeader('Content-Type', 'application/json');

            // return xhr.send(data);

            const headers = {
                'Content-Type': 'application/json'
            };

            return await logResponseAPI(data, headers)
                .then((res) => {
                    console.log(res);
                    //if (this.status >= 200 && this.status < 300) {
                    //         // return console.log(xhr.response);
                    //     } else {
                    //     return reject({
                    //         status: this.status,
                    //         statusText: xhr.statusText
                    //     });
                    //     }
                    return res
                })
                .catch((err) => {
                    console.log(err);
                    return err
                });
        }

        const applePayPaymentRequest = async () => {
            // try {
            // Define PaymentMethodData
            const paymentMethodData = [{
                "supportedMethods": "https://apple.com/apple-pay",
                "data": {
                    "version": 3,
                    "merchantIdentifier": "merchant.com.moshygaming.prelive",
                    "merchantCapabilities": [
                        "supports3DS",
                        "supportsCredit",
                        "supportsDebit"
                    ],
                    "supportedNetworks": [
                        "amex",
                        "discover",
                        "masterCard",
                        "visa"
                    ],
                    "countryCode": "US",
                    "supportedCountries": "US"
                }
            }];
            // Define PaymentDetails
            const paymentDetails = {
                "total": {
                    "label": "Buy " + coins.value['action'],
                    "amount": {
                        "value": parseInt(coins.value['total']),
                        "currency": "USD"
                    }
                }
            };
            // Define PaymentOptions
            const paymentOptions = {
                "requestPayerName": false,
                "requestPayerEmail": false,
                "requestPayerPhone": false
            };

            // Create PaymentRequest
            const request = new PaymentRequest(paymentMethodData, paymentDetails, paymentOptions);

            request.onmerchantvalidation = event => {
                // Call your own server to request a new merchant session.
                console.clear();
                event.method = 'onvalidatemerchant'
                sendResponse(JSON.stringify(event))
                console.log(event)
                const merchantSessionPromise = validateMerchant();
                event.complete(merchantSessionPromise);
            };

            request.onpaymentmethodchange = event => {
                if (event.methodDetails.type !== undefined) {
                    // Define PaymentDetailsUpdate based on the selected payment method.
                    // No updates or errors needed, pass an object with the same total.
                    const paymentDetailsUpdate = {
                        'total': paymentDetails.total
                    };
                    event.updateWith(paymentDetailsUpdate);
                }
            };

            console.log('request', request);

            const response = await request.show();

            response.test.method = 'onpaymentauthorized'
            sendResponse(JSON.stringify(response))

            const paymentToken = JSON.stringify(response.details.token, null, 4)
            const status = "success";
            await response.complete(status);
            // } catch (e) {
            //     console.log(e);
            //     // Handle errors
            //     sendResponse(JSON.stringify({error:true,message:e}))
            // }
        }

        const applePayJS = async () => {
            const apButtonArea = document.getElementById('apple-btn-area')
            // Define ApplePayPaymentRequest
            const request = {
                "countryCode": "US",
                "currencyCode": "USD",
                "merchantCapabilities": [
                    "supports3DS",
                    "supportsCredit",
                    "supportsDebit"
                ],
                "supportedNetworks": [
                    "visa",
                    "masterCard",
                    "amex",
                    "discover"
                ],
                "total": {
                    "label": "Buy " + coins.value['action'],
                    "type": "final",
                    "amount": parseInt(coins.value['total']),
                }
            };

            // Create ApplePaySession
            const session = new ApplePaySession(3, request);

            session.onvalidatemerchant = async event => {
                // loading.value = true;

                // fetch(window.location.origin + '/api/apple-pay/authorize-merchant', {
                //     method: 'post',
                //     headers: {
                //         'Accept': 'application/json',
                //         'Content-Type': 'application/json'
                //     },
                //     body: JSON.stringify({ url: event.validationURL })
                // })
                //     .then(res => res.json())
                //     .then(s => {
                //         // loading.value = false;
                //         if (s.error) {
                //             apButtonArea.classList.remove("hidden");
                //             props.closeModal();
                //             openPaymentFailed();
                //             sendResponse(JSON.stringify({ error: true, data: s }))
                //             return false;
                //         }
                //         session.completeMerchantValidation(s);
                //     })
                //     .catch(e => {
                //         apButtonArea.classList.remove("hidden");
                //         props.closeModal();
                //         openPaymentFailed();
                //         sendResponse(JSON.stringify({ error: true, message: e }))
                //     })

                const body = JSON.stringify({ url: event.validationURL })
                const headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                };

                await authorizeMerchantAPI(body, headers)
                    .then((res) => {
                        console.log('Res',res);
                        // loading.value = false;
                        if (res.error) {
                            apButtonArea.classList.remove("hidden");
                            props.closeModal();
                            openPaymentFailed();
                            sendResponse(JSON.stringify({ error: true, data: res }))
                            return false;
                        }
                        session.completeMerchantValidation(res);
                    })
                    .catch((err) => {
                        console.log(err)
                        apButtonArea.classList.remove("hidden");
                        props.closeModal();
                        openPaymentFailed();
                        sendResponse(JSON.stringify({ error: true, message: err }))
                    });
            };

            session.onpaymentmethodselected = () => {
                var newTotal = request.total;
                var newLineItems = [newTotal]
                session.completePaymentMethodSelection(newTotal, newLineItems);
            };

            session.onpaymentauthorized = async event => {
                apButtonArea.classList.add("hidden");
                loading.value = true;
                const payment = event.payment;
                var e = {}
                e.method = 'onpaymentauthorized';
                e.money = request.total.amount;
                e.payment = payment;

                console.log(e)

                // fetch(window.location.origin + '/api/apple-pay/payment', {
                //     method: 'post',
                //     headers: {
                //         'Accept': 'application/json',
                //         'Content-Type': 'application/json'
                //     },
                //     body: JSON.stringify(e)
                // })
                //     .then(res => res.json())
                //     .then(s => {
                //         loading.value = false;
                //         apButtonArea.classList.remove("hidden");
                //         if (s.status) {
                //             props.closeModal();
                //             openPaymentSuccess({
                //                 gc: s.gold_coins,
                //                 sc: s.silver_coins,
                //             });
                //             props.setPaymentSuccessCoins(s);
                //         }
                //         else {
                //             sendResponse(JSON.stringify({ error: true, data: s }))
                //             props.closeModal();
                //             openPaymentFailed();
                //         }
                //     })
                //     .catch(e => {
                //         apButtonArea.classList.remove("hidden");
                //         loading.value = false;
                //         props.closeModal();
                //         openPaymentFailed();
                //         sendResponse(JSON.stringify({ error: true, message: e }))
                //     })

                const body = JSON.stringify(e);
                const headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                };

                await paymentAPI(body, headers)
                    .then((res) => {
                        console.log('Res',res)
                        // loading.value = false;
                        loading.value = false;
                        apButtonArea.classList.remove("hidden");
                        if (res.status) {
                            props.closeModal();
                            openPaymentSuccess({
                                gc: res.gold_coins,
                                sc: res.silver_coins,
                            });
                            props.setPaymentSuccessCoins(res);
                        }
                        else {
                            sendResponse(JSON.stringify({ error: true, data: res }))
                            props.closeModal();
                            openPaymentFailed();
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        apButtonArea.classList.remove("hidden");
                        loading.value = false;
                        props.closeModal();
                        openPaymentFailed();
                        sendResponse(JSON.stringify({ error: true, message: err }))
                    });

                // Define ApplePayPaymentAuthorizationResult
                const result = {
                    "status": ApplePaySession.STATUS_SUCCESS
                };
                session.completePayment(result);
            };

            session.oncancel = event => {
                apButtonArea.classList.remove("hidden");
                event.method = 'oncancel'
                sendResponse(JSON.stringify(event))
            };

            session.begin();
        }

        const apButton = () => {
            loading.value = true;
            props.setLoading(true);
            const apButtonArea = document.getElementById('apple-btn-area')
            const apButtonTab = document.getElementById('ap-button-tab');
            const applePayBtn = document.querySelector('apple-pay-button');
            const apButtonPanel = document.getElementById('ap-button-panel');
            apButtonArea.classList.add("hidden");
            if (window.ApplePaySession) {
                props.setLoading(false);
                setTimeout(() => {
                    loading.value = false;
                    apButtonArea.classList.remove("hidden");
                }, 1000);
                apButtonTab.classList.remove("hidden");
                apButtonPanel.classList.remove("hidden");
                applePayBtn.addEventListener('click', async () => {
                    //   if (window.PaymentRequest){
                    //     await applePayPaymentRequest();
                    //   }
                    //   else{
                    await applePayJS();
                    //   }
                });
            }
        }

        const openPaymentSuccess = (coins) => {
            eventBus.emit("open:payment_success", coins);
        };

        const openPaymentFailed = () => {
            eventBus.emit("open:payment_failed");
        };

        onMounted(async () => {
            // initializeApplePay();

            loading.value = true;

            apButton();
        });

        onActivated(() => {
            // initializeApplePay();

            apButton();
        });

        return {
            loading
        }
    },
};
</script>

<style>
apple-pay-button {
    --apple-pay-button-width: 150px;
    --apple-pay-button-height: 30px;
    --apple-pay-button-border-radius: 100px;
    --apple-pay-button-padding: 0px 0px;
    --apple-pay-button-box-sizing: border-box;
}
</style>
