<template>
    <transition
         enter-from-class="scale-0"
         enter-active-class="ease-in transition-medium"
         enter-to-class="scale-1"
         leave-from-class="scale-1"
         leave-active-class="ease-out transition-medium"
         leave-to-class="scale-0"
    >
         <div
              v-if="isOpenStats"
              id="authentication-modal"
              tabindex="-1"
              aria-hidden="true"
              class="overflow-y-auto overflow-x-hidden fixed z-80 w-full inset-0 max-h-full transform ease-in-out transition-all duration-300 self-center w-full m-auto inset-0 font-helveticaLight"
         >
              <div class="relative px-6 py-3 sm:py-4 sm:px-10 w-full max-w-lg max-h-full m-auto">
                   <!-- Modal content -->
                   <div class="relative bg-shade-1 rounded-xl shadow-xl">
                        <button
                             @click="closeModal()"
                             type="button"
                             class="close-btn-c absolute top-2.5 end-2.5 rounded-xl text-sm ms-auto inline-flex justify-center items-center"
                             data-modal-hide="authentication-modal"
                        >
                             <img
                                  :src="baseUrl.img+'/assets/img/modal/exclude.svg'"
                                  alt=""
                             />
                             <span class="sr-only">Close modal</span>
                        </button>
                        <div class="flex flex-col divide-y divide-shade-12 items-center justify-center py-6 lg:py-8 font-roboto text-shade-2 font-bold text-sm xs:text-base px-4 sm:px-6 lg:px-8 tracking-wide leading-tight">
                             <div class="pb-4 w-full text-center">
                                  <h3 class="text-base xs:text-lg font-bold text-gray-900 mb-2 xl:mb-3">
                                       My stats
                                  </h3>
                                  <h1
                                        class="text-black font-helvetica tracking-wider text-3xl xs:text-3xl sm:text-4xl md:text-4xl xl:text-5xl leading-3xl xs:leading-3xl sm:leading-4xl md:leading-4xl xl:leading-5xl mb-3 xl:mb-5">
                                        THANK YOU!
                                   </h1>
                                   <p class="font-bold mb-3 xl:mb-4 text-xs sm:text-sm text-left">You will be notified and receive 10000 GC code via email once a friend complete their KYC using your referral link. </p>
                             </div>
                             <div class="py-3 md:py-6 xl:py-8 w-full">
                                  <div class="flex divide-x divide-shade-12 gap-2 sm:gap-3 md:gap-4 lg:gap-5">
                                        <div class="flex-1 flex flex-col items-center justify-center gap-2 sm:gap-4 py-2 text-center">
                                             <span class="text-black">{{ referral_stats?.count ? referral_stats?.viewed : '-' }}</span>
                                             <span class="text-black text-xs sm:text-sm">Times Shared</span>
                                        </div>
                                        <div class="flex-1 flex flex-col items-center justify-center gap-2 sm:gap-4 py-2 text-center">
                                             <span class="text-black">{{ referral_stats?.count ? referral_stats?.count : '-' }}</span>
                                             <span class="text-black text-xs sm:text-sm">Friends Referred</span>
                                        </div>
                                  </div>
                             </div>
                             <div class="pt-2 xs:pt-4 text-center w-full tracking-wide helveticalight">
                                  <div class="pb-4 xs:pb-6">
                                       <div class="text-xs xs:text-sm flex flex-wrap items-center font-normal">
                                            <div class="flex-1 flex flex-wrap gap-2 md:gap-3 items-center justify-center">
                                                  <img class="cursor-pointer w-6 md:w-8" :src="baseUrl.img+'/assets/img/header/GC.svg'" alt="">
                                                 <span class="text-secondary-7 text-base xs:text-lg md:text-xl xl:text-2xl font-bold">{{ getTotalReferralGC(referral_stats?.breakdown) }}</span>
                                            </div>
                                            <div class="flex-1 flex flex-wrap gap-2 md:gap-3 items-center justify-center">
                                                  <img class="cursor-pointer w-6 md:w-8" :src="baseUrl.img+'/assets/img/header/SC.svg'" alt="">
                                                 <span class="flex items-center text-tertiary-3 text-base xs:text-lg md:text-xl xl:text-2xl font-bold"><span class="leading-none text-tertiary-3 text-base xs:text-lg md:text-xl xl:text-2xl font-bold mr-1">+</span> FREE {{ getTotalReferralSC(referral_stats?.breakdown) }}</span>
                                            </div>
                                       </div>
                                  </div>
                                  <div class="px-2">
                                       <span
                                            class="w-full text-black px-4 py-3 xl:py-3.5 text-center leading-tight rounded-full font-bold"
                                       >
                                            Earned
                                       </span>
                                  </div>
                             </div>
                        </div>
                   </div>
              </div>
         </div>
    </transition>
    <div
         v-if="isOpenStats"
         class="fixed inset-0 z-60"
         style="background-color: rgba(0, 0, 0, 0.3)"
    ></div>
</template>

 <script>
import { ref, computed, onMounted, onUnmounted, onBeforeMount } from "vue";
import eventBus from "plugins/event.js";
import { utilitiesStore } from "store/utilities.js";
// import { useFormatter } from "composables/use-formatter.js";
import { bonusStore } from "@/store/bonus";
import _ from "underscore";
import { baseUrl } from "config/services.js";

export default {
    props: {
    },
    components: {
    },
    setup() {
     //     const { money } = useFormatter();
         const isOpenStats = ref(false);
         const loading = ref(false);
         const useUtilitiesStore = utilitiesStore();
         const useBonusStore = bonusStore();

         const referral_stats = computed(() => {
            return useBonusStore.referral_stats;
          });
         
         const toggleStats = () => {
              isOpenStats.value = !isOpenStats.value;
         };

         const closeModal = () => {
              isOpenStats.value = false;
              useUtilitiesStore.enableScroll();
         };

         const getTotalReferralGC = (breakdown) => {
               return _.reduce(breakdown, (sum, breakdown) => {
                    return sum + JSON.parse(breakdown.claim_amount).gold_coins;
               }, 0);
         };

         const getTotalReferralSC = (breakdown) => {
               return _.reduce(breakdown, (sum, breakdown) => {
                    return sum + JSON.parse(breakdown.claim_amount).silver_coins;
               }, 0);
         };

         onMounted(async () => {
              eventBus.on("open:stats", () => {
                   useUtilitiesStore.disableScroll();
                   toggleStats();
              });
              eventBus.on("close:stats", () => {
                   useUtilitiesStore.enableScroll();
                   toggleStats();
              });
         });

         onUnmounted(() => {
              eventBus.off("open:stats");
              eventBus.off("close:stats");
         });

         onBeforeMount(async () => {
            await useBonusStore.getReferralStats();
         });

         return {
              loading,
              isOpenStats,
              closeModal,
              referral_stats,
              getTotalReferralGC,
              getTotalReferralSC,
              baseUrl
         };
    },
};
</script>
