<template>

     <InertiaHead title="Privacy Policy"></InertiaHead>
     <div class="home-wrapper mt-0">
          <div class="z-0 w-full h-100-vh welcome-bg-container bg-background-2 fixed top-0 left-0"></div>
          <div class="banner flex bg-no-repeat w-full font-helveticaLight flex-col justify-center items-center relative">
               <div class="text-center">
                    <div class="pt-18 md:pt-22 lg:pt-28 text-white font-helvetica tracking-wide text-center text-4xl xs:text-6xl sm:text-7xl md:text-7xl xl:text-8xl leading-3xl xs:leading-5xl sm:leading-6xl md:leading-6xl xl:leading-7.5xl uppercase px-2">
                         Privacy Policy
                    </div>
               </div>
               <div class="w-full flex justify-center items-center pb-20">
                    <div class="py-14 sm:py-16 md:py-20 lg:py-28 px-4 xs:px-10 sm:px-20 lg:px-24 xl:px-36 2xl:px-64">
                         <div v-if="!loading" class="pb-2" v-html="content"></div>
                         <loading-small v-else :isLoading="loading" />
                         <!-- <div class="bg-white rounded-xl py-6 sm:py-12 md:py-20 lg:py-28 px-6 xs:px-10 sm:px-20 lg:px-24 xl:px-36 2xl:px-48 shadow-xl">
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Moshy Gaming LLC provides a social casino platform through <a href="https://www.moozi.com" class="text-primal-blue">https://www.moozi.com</a> and any associated websites and mobile versions ("Moozi", “Site”, "us", "our", "ours", etc.). Upholding your privacy is of paramount importance, and we are steadfast in safeguarding it through this Privacy Policy (the "Policy"). This Policy delineates the categories of personal data we may collect from you, the user (referred to herein as "you," "your," "yours," etc.), or that you may furnish to us when utilizing the services provided by us on the Site (the "Services"). Moreover, it outlines our procedures for the acquisition, utilization, retention, protection, and disclosure of your personal data.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Please read this Policy thoroughly. Should you dissent from this Policy, your only recourse is to discontinue using the Site. By accessing or utilizing the Site, you agree to abide by this Policy and consent to our acquisition, utilization, disclosure, retention, and safeguarding of your data as delineated herein.
                              </p>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We retain the prerogative to revise, amend, or modify this. Your continued use of the Site following such modifications to this Policy shall be construed as your acceptance of said modifications. Therefore, it is imperative that you periodically review this Policy and verify the "Last Updated" date indicated above. In the event of changes, the revised or amended version of this Policy shall supersede all prior iterations upon its posting.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">I.</span><span class="">How old do you have to be to use the Services?</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Access to the Site or utilization of the Services is strictly prohibited for individuals under the age of eighteen (18). We do not engage in targeted marketing, nor do we knowingly collect or solicit any data from individuals under the age of eighteen (18). If you are under the age of eighteen (18), refrain from providing any information to us and promptly exit the Site. In the event that we inadvertently obtain information from or about an individual under the age of eighteen (18), we will expeditiously delete such data. If you suspect that we may possess such information, please reach out to us at
                                   <a
                                        class="text-primal-blue ml-1 underline"
                                        href="support@moozi.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                   >support@moozi.com</a>.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">II.</span><span class="">What types of information do we collect about you?</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We collect two types of information – personal and anonymous information. Personal information is any information or opinion (whether true or not) about an identifiable individual, or an individual who can reasonably be ascertained from that information. We aim to collect personal information only if it is reasonably necessary to provide the Service or information you request of us. Anonymous information is defined as information that does not directly or indirectly identify, and cannot reasonably be used to identify, an individual.
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   In general you can assume that Moozi collects the following personal and non-personally identifiable (anonymous) information:
                              </p>
                              <br />
                              <ul class="ml-8">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">●</span>Your name, residential address, email address, profile picture and phone number;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">●</span>Your use of the Services which are provided by Moozi to you;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">●</span>Information sent via email, post or other methods of communication;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">●</span>Contents of your customer support requests;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">●</span>Location information provided by a device interacting with our Site or from your IP address, where we are permitted by law to process this information;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">●</span>Activity data, including the content you view, the date and time of your visit on our Site, pages viewed while browsing the Site, frequency of use of our Services, information about your preferences and interaction with the content offered through our Site;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">●</span>Technical and device information, including your browser type, hardware model, operating system, device type, IP address and other unique device identifiers; and
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">●</span>Any other information included in your registration, social network or online profile.
                                   </li>
                              </ul>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">III.</span><span class="">How do we collect your personal information?</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We acquire your personal data directly from, collection may occur through various channels, including but not limited to:
                              </p>
                              <br />
                              <ul class="ml-8">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Account registration;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Profile particulars;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Transactions made on www.moozi.com;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Search history;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Interactions via social media profiles;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Correspondence submitted via mail-in requests for SCs  <a
                                             class="text-primal-blue ml-1 underline"
                                             href="/sc-coins"
                                             target="_blank"
                                             rel="noopener noreferrer"
                                        >www.moozi.com/sc-coins</a>;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Participation in surveys administered;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Any other communications or engagements with us, whether initiated through contact forms, phone calls, postal mail, emails, text messages, or other mediums, including interactions on third-party social media platforms; and/or
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Utilization of our technologies, encompassing our servers, log files, cookies, pixel tags, and analytical services.
                                   </li>
                              </ul>
                              <br />
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We automatically gather your personal data from your computing device or mobile phone, facilitated by mechanisms such as cookies, web beacons, and other tracking technologies. This Policy does not pertain to information acquired through offline channels, alternative means, or data collected by any third party linked to or accessible through the Site.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">IV.</span><span class="">Do third parties collect my personal information on the Site?</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Various third parties, including users, advertisers, content or application providers, and third-party plugins, may furnish materials to the Site utilizing cookies, web beacons, or analogous tracking technologies to gather your personal data. This information may encompass details regarding your online behaviors over time and across diverse websites and other digital platforms. Said third parties might leverage this data to furnish you with interest-based (behavioral) advertising or tailored content. We do not exercise authority over third-party tracking technologies or their utilization by third parties. Your engagement with third-party plugins is subject to the terms of use and privacy policy of the respective third-party provider that supplies the plugin. We explicitly disclaim any liability for the safeguarding of your personal information on third-party websites, and we do not provide any assurances or assertions regarding the seamless operation of any third-party website (including this Site), the rectification of flaws, or the absence of viruses or other potentially harmful elements on their servers.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify underline">
                                Use of Google Analytics
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                Our website uses Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses cookies, which are small text files placed on your device, to help us analyze how users interact with our site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on its servers.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                Google uses this information to evaluate your use of our website, compile reports on website activity, and provide other services related to website activity and internet usage. This information helps us to improve the functionality and user experience of our website.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                Analytics tracking by using the Google Analytics Opt-out Browser Add-on, available at
                                <a
                                    href="https://tools.google.com/dlpage/gaoptout"
                                    class="text-primal-blue ml-1 underline"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://tools.google.com/dlpage/gaoptout
                                </a>.
                              </p>
                              <br>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                Please note that by using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above. For more information on Google's privacy practices, please visit Google's Privacy Policy at
                                <a
                                    href="https://policies.google.com/privacy"
                                    class="text-primal-blue ml-1 underline"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://policies.google.com/privacy
                                </a>.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">V.</span><span class="">How do we use your personal information?</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We may, for example, utilize your personal information for the following purposes:
                              </p>
                              <br />
                              <ul class="ml-8">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Facilitating access to the Site and its Services;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Enhancing the efficiency of the Services, such as through automatic updates of your account details;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Recognizing your identity upon your return to the Services;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Tailoring the Services to align with your preferences and individual interests;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Fulfilling our contractual obligations and enforcing our rights as stipulated in agreements between you and us, including this Policy and our Terms of Service Agreement; and/or
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Investigating and preventing unauthorized or prohibited utilization of the Services.
                                   </li>
                              </ul>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">VI.</span><span class="">Do we share your personal information with third parties?</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We reserve the right to share publicly available information and de-identified data with third parties without constraint. However, disclosure of your personal information may be limited to the following circumstances:
                              </p>
                              <br />
                              <ul class="ml-8">
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Our subsidiaries, affiliates, contractors, service providers, and other relevant third parties as required to furnish the Services;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Prospective buyers or other entities assuming control in scenarios involving mergers, joint ventures, assignments, divestitures, restructurings, reorganizations, dissolutions, or other transactions entailing the sale or transfer of assets, encompassing instances of bankruptcy, liquidation, or analogous proceedings;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Law enforcement entities, government agencies, and private litigants, in compliance with lawful criminal, civil, or administrative processes, including but not restricted to subpoenas, court orders, writs, or reasonable requests from authorities or individuals empowered to obtain such processes;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Any other party essential for the identification, communication with, or initiation of legal action against individuals potentially violating our policies;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Any other party necessitated to fulfill legal or tax obligations or safeguard our legitimate interests;
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Any other party indispensable to safeguard our rights, assets, or the well-being of ourselves, our users, or the public at large, which may encompass disclosures for fraud prevention and mitigation of credit risks; and
                                   </li>
                                   <li class="mb-2 text-sm sm:text-base lg:text-lg flex flex-row">
                                        <span class="mr-5">-</span>Any other party contingent upon your consent.
                                   </li>
                              </ul>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">VII.</span><span class="">What choices do you have over your personal information?</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   You maintain the ability to delete or limit any personal data you have provided directly to us. Upon your request for the permanent deletion of your account, we will erase personal information you have directly provided. Nevertheless, we reserve the right to retain your personal data for purposes outlined herein. Additionally, we reserve the prerogative to decline any alterations if we deem such actions to contravene applicable laws, legal requisites, or to render the information inaccurate potentially. We do not govern the collection or utilization of your personal information by third parties.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">VIII.</span><span class="">How long do we retain your personal information?</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   Unless otherwise sanctioned or mandated by applicable law or regulation, we will retain your personal information solely for the duration necessary to fulfill any intended utilization outlined herein. Nonetheless, we maintain the discretion to preserve publicly available information and de-identified data for any legitimate business objective, without consent.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">IX.</span><span class="">Is my personal information secure?</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   We are dedicated to upholding data security standards, and as such, have instituted protocols aimed at safeguarding your personal. All data provided to us is securely stored on our servers, protected by robust firewalls. We cannot ensure the absolute security of personal information transmitted via the Site. Consequently, any transmission of personal data is undertaken at your own risk. We disclaim responsibility for any attempts to circumvent privacy settings or security measures implemented by the Site.
                              </p>

                              <br /><br />
                              <p class="mb-3 font-bold text-base sm:text-lg lg:text-xl">
                                   <span class="mr-5">X.</span><span class="">No Third-Party Rights</span>
                              </p>
                              <p class="mb-0 text-sm sm:text-base lg:text-lg text-justify">
                                   This Policy does not establish rights enforceable by third parties or necessitate the disclosure of any personal information pertaining to users of the Services.
                              </p>
                         </div> -->
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { Head as InertiaHead } from "@inertiajs/vue3";
import { ref,computed, onMounted } from "vue";
import { policyStore } from "store/policy.js";
import _ from "underscore";
import LoadingSmall from "components/loading/loadingSmall.vue";
import { useScroll } from "composables/use-scroll.js";

export default {
     components: {
          InertiaHead,
          LoadingSmall,
     },
     setup() {
          const slug = 'privacy';
          
          const { scrollTop } = useScroll();

          const usePolicyStore = policyStore()

          const loading = ref(false);

          const content = computed(() => {
               if (usePolicyStore.policy) {
                    const filter = _.find(usePolicyStore.policy, function (params) {
                         return params['slug'] == slug
                    })

                    return filter ? filter.content : '';
               }

               return ''
          })

          onMounted(async () => {
               loading.value = true
               scrollTop();
               await usePolicyStore.getPolicy();
               loading.value = false
          })

          return {
               content,
               loading
          };
     },
};
</script>
