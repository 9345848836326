<template>
     <div class="w-full">
          <div class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-3 md:gap-5">
               <div 
                    v-for="(item, key) in allProvider" 
                    :key="key" 
                    class="bg-primary-1 rounded-md cursor-pointer scale-100 hover:scale-105 transition-transform duration-300"
                    @click="goTo(item.name)"
               >
                    <img v-if="item.icon != null" :src="baseUrl.img+''+item.icon" class="hidden lg:block w-full h-auto" :alt="item.name" srcset="">
                    <img v-if="item.icon_mobile != null" :src="baseUrl.img+''+item.icon_mobile" class="block lg:hidden w-full h-auto" :alt="item.name" srcset="">
               </div>
          </div>
     </div>
</template>
<script>
import { computed } from 'vue';
import { gamesStore } from "store/games";
import { baseUrl } from "config/services.js";

export default {
     props: ["goTo"],
     setup() {

          const useGamesStore = gamesStore();

          const allProvider = computed(() => {
               return useGamesStore.allProvider || null;
          });

          return {
               allProvider,
               baseUrl
               
          };
     },
};
</script>
