<template>
     <div class="w-full h-full flex flex-col items-center justify-center">
          <h1 :class="lightMode ? 'text-shade-7' : 'text-white'" class="text-center w-full font-roboto tracking-wide text-xs md:text-xs lg:text-sm xl:text-lg">
               You are currently self excluded. This ends in {{ selfExclusion != null ? selfExclusionTimeFormat(selfExclusion) : '0 days' }} <br/>
               If you would like to out of self exclusion please turn off the self exclusion in <span @click="goToResponsible()" class="underline underline-offset-2 cursor-pointer">Responsible Play</span>.
          </h1>
          <img
               :src="baseUrl.img+'/assets/img/blade/restricted.png'"
               alt="cow"
               class="mt-8 md:mt-16 mb-8 h-58 lg:h-auto"
          />
     </div>
</template>
<script>
import { useRouter } from "vue-router";
import { useFormatter } from "composables/use-formatter.js";
import { baseUrl } from "config/services.js";

export default {
     props: ['selfExclusion', 'lightMode'],
     setup() {
          const router = useRouter();
          const { selfExclusionTimeFormat } = useFormatter();

          const goToResponsible = () => {
               router.push({ path: "/account/", query: { tab: 'responsible'}});
          };

          return {
               selfExclusionTimeFormat,
               goToResponsible,
               baseUrl
          }
     }
}
</script>