<template>

     <InertiaHead title="Game Details"></InertiaHead>
     <div class="w-full h-full relative flex justify-center mt-0">
          <div class="z-0 w-full h-100-vh welcome-bg-container bg-background-2 fixed top-0 left-0"></div>
          <div class="w-full 3xl:max-w-screen-3xl bg-transparent relative">
               <div class="w-full h-full flex flex-col justify-center items-center px-5 xs:px-8 sm:px-18 md:px-24 lg:px-28 xl:px-32 pb-16 sm:pb-18 md:pb-20 lg:pb-28 xl:pb-32">
                    <div
                         v-if="gamesDetails"
                         class="flex flex-col 2xl:flex-row items-start gap-5 2xl:gap-12 3xl:gap-16 w-full lg:w-10/12 xl:w-12/12 2xl:w-full 3xl:w-10/12 mt-16 md:mt-20 lg:mt-18"
                    >
                         <div class="w-full 2xl:w-6/12 3xl:w-6/12">
                              <img
                                   v-if="gamesDetails?.game_pic"
                                   :src="gamesDetails?.game_pic"
                                   class="w-full rounded-3xl"
                              >
                              <img
                                   v-else
                                   :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                                   class="w-full rounded-3xl"
                              >
                         </div>
                         <div class="w-full 2xl:w-8/12 3xl:w-6/12">
                              <div class="bg-background-1 b rounded-xl px-5 lg:px-6 xl:px-10 3xl:px-16 py-5 text-white flex flex-col justify-center items-center shadow-box">
                                   <h3 class="text-white font-helvetica tracking-wide text-2xl xs:text-3xl sm:text-5xl md:text-6xl lg:text-4xl xl:text-5xl 2xl:text-4xl leading-xl xs:leading-3xl sm:leading-5xl md:leading-5xl lg:leading-4xl xl:leading-5xl 2xl:leading-4xl uppercase">
                                        {{gamesDetails?.game_name}}
                                   </h3>
                                   <div v-if="isUser && !checkActionRestricted()" class="flex flex-col md:flex-row justify-center gap-3 w-full">
                                        <div @click="() => openLaunchConfirmation(gamesDetails, 'gold')" class="relative w-full lg:w-max mt-5 md:mt-7 lg:mt-5 rounded-full font-roboto font-extrabold tracking-wider px-16 py-3 bg-secondary-3 text-primary-1 text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl cursor-pointer flex flex-row justify-center items-center gap-3">
                                             <span>
                                                  Launch
                                             </span>
                                             <img class="absolute right-2 cursor-pointer -ml-2 w-8 2xl:w-auto" :src="baseUrl.img+'/assets/img/header/GC.svg'" alt="" style="filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));">
                                        </div>
                                        <div @click="() => openLaunchConfirmation(gamesDetails, 'silver')" class="relative w-full lg:w-max md:mt-7 lg:mt-5 rounded-full font-roboto font-extrabold tracking-wider px-16 py-3 bg-tertiary-3 text-primary-1 text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl cursor-pointer flex flex-row justify-center items-center gap-3">
                                             <span>
                                                  Launch
                                             </span>
                                             <img class="absolute right-2 cursor-pointer -ml-2 w-8 2xl:w-auto" :src="baseUrl.img+'/assets/img/header/SC.svg'" alt="" style="filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));">
                                        </div>
                                   </div>
                                   <div class="w-full" :class="isUser ? 'mt-3 lg:mt-7 mb-3' : 'mt-5'"></div>
                                   <div class="px-2 md:px-4">
                                        <p v-html="gamesDetails?.game_description" class="text-white font-helveticaLight tracking-wide text-start text-xs xs:text-sm sm:text-lg md:text-xl lg:text-lg xl:text-xl">
                                        </p>
                                   </div>
                              </div>
                              <div class="flex flex-col gap-3 w-full mt-5">
                                   <div class="flex flex-row gap-3 w-full">
                                        <div class="bg-background-1 rounded-xl px-4 py-3 w-1/2 shadow-box">
                                             <p class="flex items-center gap-2 text-white font-roboto font-extrabold tracking-wider text-sm sm:text-lg md:text-xl lg:text-lg xl:text-lg uppercase">
                                                  <span class="text-background-3">Game type:</span>
                                                  <span
                                                       class="text-3xl"
                                                       :class="'icon-'+gamesDetails.game_type"
                                                  ></span>
                                                  {{gamesDetails?.game_type}}
                                             </p>
                                        </div>
                                        <div class="bg-background-1 rounded-xl px-4 py-3 w-1/2 shadow-box">
                                             <p class="text-white font-roboto font-extrabold tracking-wider text-sm sm:text-lg md:text-xl lg:text-lg xl:text-lg uppercase">
                                                  <span class="text-background-3">Provider:</span> {{ provider[0].name }}
                                             </p>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div v-if="similarGames && similarGames.length != 0" class="w-full lg:w-10/12 xl:w-12/12 2xl:w-full 3xl:w-10/12 mt-5 2xl:mt-20">
                         <div class="bg-background-1 rounded-xl py-5 text-white flex flex-col justify-center items-center shadow-box">
                              <h3 class="text-white w-full px-5 mb-5 font-helvetica tracking-wide text-2xl xs:text-3xl sm:text-5xl md:text-6xl lg:text-4xl xl:text-5xl 2xl:text-4xl leading-xl xs:leading-3xl sm:leading-5xl md:leading-5xl lg:leading-4xl xl:leading-5xl 2xl:leading-4xl uppercase">
                                   SIMILAR GAMES
                              </h3>
                              <Carousel
                                   :itemsToShow="1.5"
                                   snapAlign="start"
                                   :transition="500"
                                   class="w-full px-2 hot-games"
                                   :breakpoints="{
                                             768: {
                                                  itemsToShow: 2.5,
                                             },
                                             1024: {
                                                  itemsToShow: 3,
                                             },
                                             1280: {
                                                  itemsToShow: 4,
                                             },
                                             1536: {
                                                  itemsToShow: 5,
                                             },
                                        }"
                              >
                                   <Slide
                                        v-for="(slide, key) in similarGames"
                                        :key="key"
                                   >
                                        <div class="carousel__item w-full">
                                             <div
                                                  class="mx-3 cursor-pointer"
                                                  @click="() => goToDetail(slide.game_name, slide.id)"
                                             >
                                                  <div class="relative items-center cursor-pointer">
                                                       <img
                                                            :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                                                            alt="game"
                                                            class="rounded-md w-full h-auto"
                                                       >
                                                       <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                                            <img
                                                                 v-if="slide.game_pic != null"
                                                                 :src="slide.game_pic"
                                                                 alt="game"
                                                                 class="rounded-md w-full hidden md:block"
                                                                 style="height: inherit;"
                                                            >
                                                            <img
                                                                 v-else
                                                                 :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                                                                 alt="game"
                                                                 class="rounded-md w-full h-auto hidden md:block"
                                                            >
                                                            <img
                                                                 v-if="slide.game_pic != null"
                                                                 :src="slide.game_pic_mobile"
                                                                 alt="game"
                                                                 class="rounded-md w-full h-auto block md:hidden"
                                                                 style="height: inherit;"
                                                            >
                                                            <img
                                                                 v-else
                                                                 :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                                                                 alt="game"
                                                                 class="rounded-md w-full h-auto block md:hidden"
                                                            >
                                                       </div>
                                                  </div>
                                                  <p class="mt-3 helveticaLight tracking-wide text-secondary-1 text-center text-sm md:text-base xl:text-lg">
                                                       {{slide?.game_name}}
                                                  </p>
                                             </div>
                                        </div>
                                   </Slide>
                              </Carousel>
                         </div>
                    </div>
                    <div class="w-full lg:w-10/12 xl:w-12/12 2xl:w-full 3xl:w-10/12 mt-5" :class="similarGames ? '2xl:mt-10' : '2xl:mt-20'">
                         <div class="bg-background-1 rounded-xl py-5 text-white flex flex-col justify-center items-center shadow-box">
                              <h3 class="text-white w-full px-5 mb-5 font-helvetica tracking-wide text-2xl xs:text-3xl sm:text-5xl md:text-6xl lg:text-4xl xl:text-5xl 2xl:text-4xl leading-xl xs:leading-3xl sm:leading-5xl md:leading-5xl lg:leading-4xl xl:leading-5xl 2xl:leading-4xl uppercase">
                                   HOT GAMES
                              </h3>
                              <Carousel
                                   :itemsToShow="1.5"
                                   snapAlign="start"
                                   :transition="500"
                                   class="w-full px-2 hot-games"
                                   :breakpoints="{
                                             768: {
                                                  itemsToShow: 2.5,
                                             },
                                             1024: {
                                                  itemsToShow: 3,
                                             },
                                             1280: {
                                                  itemsToShow: 4,
                                             },
                                             1536: {
                                                  itemsToShow: 5,
                                             },
                                        }"
                              >
                                   <Slide
                                        v-for="(slide, key) in hotGames"
                                        :key="key"
                                   >
                                        <div class="carousel__item w-full">
                                             <div
                                                  class="mx-3 cursor-pointer"
                                                  @click="() => goToDetail(slide.game_name, slide.id)"
                                             >
                                                  <div class="relative items-center cursor-pointer">
                                                       <img
                                                            :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                                                            alt="game"
                                                            class="rounded-md w-full h-auto"
                                                       >
                                                       <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                                            <img
                                                                 v-if="slide.game_pic != null"
                                                                 :src="slide.game_pic"
                                                                 alt="game"
                                                                 class="rounded-md w-full hidden md:block"
                                                                 style="height: inherit;"
                                                            >
                                                            <img
                                                                 v-else
                                                                 :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                                                                 alt="game"
                                                                 class="rounded-md w-full h-auto hidden md:block"
                                                            >
                                                            <img
                                                                 v-if="slide.game_pic != null"
                                                                 :src="slide.game_pic_mobile"
                                                                 alt="game"
                                                                 class="rounded-md w-full h-auto block md:hidden"
                                                                 style="height: inherit;"
                                                            >
                                                            <img
                                                                 v-else
                                                                 :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                                                                 alt="game"
                                                                 class="rounded-md w-full h-auto block md:hidden"
                                                            >
                                                       </div>
                                                  </div>
                                                  <p class="mt-3 helveticaLight tracking-wide text-secondary-1 text-center text-sm md:text-base xl:text-lg">
                                                       {{slide?.game_name}}
                                                  </p>
                                             </div>
                                        </div>
                                   </Slide>
                              </Carousel>
                         </div>
                    </div>
               </div>
          </div>
          <loading-overlay :isLoading="loading" />
          <LaunchConfirmation :onLaunchGame="onLaunch" :gameDetails="activeGameDetails" :type="activeType" />
     </div>
</template>
<script>
import { Head as InertiaHead } from "@inertiajs/vue3";
import {
     gamesDetails as gamesDetailsAPI,
     games as gamesAPI,
} from "@/api/games";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Carousel, Slide } from "vue3-carousel";
import { useToaster } from "composables/use-toaster.js";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import { gamesStore } from "store/games";
import { computed } from "vue";
import _ from "underscore";
import { authStore } from "store/auth.js";
import { gameLauncher as gameLauncherAPI } from "@/api/seamless";
import { gamesSimilar as gamesSimilarAPI } from "@/api/games";
import eventBus from "plugins/event.js";
import { useScroll } from "composables/use-scroll.js";
import { baseUrl } from "config/services.js";
import LaunchConfirmation from "components/modals/launchConfirmation.vue";
import { useKYC } from 'composables/use-kyc.js';
import { useActionRestriction } from 'composables/use-restriction.js';

export default {
     components: {
          InertiaHead,
          Carousel,
          Slide,
          LoadingOverlay,
          LaunchConfirmation
     },
     setup() {
          const { open_kyc_game_launcher } = useKYC();
          const { isActionRestricted } = useActionRestriction();

          const route = useRoute();
          const router = useRouter();

          const loading = ref(false);

          const gamesDetails = ref(null);
          const hotGames = ref(null);
          const pageRandom = ref(1);

          const provider = ref(null);
          const similarGames = ref(null);

          const activeGameDetails = ref(null);
          const activeType = ref(null);

          const { scrollTop } = useScroll();

          const useGamesStore = gamesStore();
          const useAuthStore = authStore();

          const { errorToast } = useToaster();

          const providerData = computed(() => {
               return useGamesStore.provider || null;
          });

          const isUser = computed(() => {
               return useAuthStore.user;
          });

          const getGame = async () => {
               await gamesDetailsAPI({
                    game_id: route.path.split("/")[3],
               })
                    .then((res) => {
                         gamesDetails.value = res;

                         provider.value = _.filter(
                              providerData.value,
                              function (params) {
                                   return (
                                        params["id"] == res?.provider_id
                                   );
                              }
                         );

                    })
                    .catch(() => {
                         errorToast("Something went wrong!", "top-right");
                         router.push({ name: "NotFound" });
                    });
          };

          const getHotGames = async () => {
               await gamesAPI({
                    page: pageRandom.value,
                    itemsPerPage: 20,
                    game_type: [],
                    game_promo: 1,
                    game_name: "",
                    provider_id: [],
               })
                    .then((res) => {
                         hotGames.value = res?.games?.data;
                         pageRandom.value =
                              res?.games?.last_page == 1
                                   ? 1
                                   : Math.floor(
                                          Math.random() *
                                               (res?.games?.last_page - 2) +
                                               1
                                     );
                    })
                    .catch(() => {
                         errorToast("Something went wrong!", "top-right");
                    });
          };

          const goToDetail = async (name, id) => {
               loading.value = true;
               router.push({
                    path:
                         "/games/detail/" +
                         name.replaceAll(" ", "-") +
                         "-" +
                         id,
               });
               setTimeout(async () => {
                    scrollTop();
                    await getGame();
                    await getSimilarGames();
                    await getHotGames();
                    loading.value = false;
               }, 1000);
          };

          const onLaunch = async (slug, type) => {
               if (isUser.value) {
                    if(open_kyc_game_launcher() == true) {
                         return;
                    };

                    const filter = _.filter(
                         providerData.value,
                         function (params) {
                              return params.id == slug.provider_id;
                         }
                    );

                    await gameLauncherAPI(
                         { game_slug: slug.game_slug, type: type },
                         filter[0]["game_source"]
                    )
                         .then((res) => {
                              if (res.status) {
                                   if (
                                        /iPad|iPhone|iPod/.test(
                                             navigator.userAgent
                                        )
                                   ) {
                                        // alert("This is an iOS device.");
                                        setTimeout(() => {
                                            //  window.open(res.url, "_blank");
                                            closeLaunchConfirmation();
                                            useAuthStore.setActiveCoinType(type);
                                            useAuthStore.setActiveGameUrl(res.url);
                                            router.push({ name: 'play', params: { slug: slug.game_slug, id: slug.provider_id, launchcode: res.launch_code, type: type } });
                                        });
                                   } else {
                                        // window.open(res.url, "_blank");
                                        closeLaunchConfirmation();
                                        useAuthStore.setActiveCoinType(type);
                                        useAuthStore.setActiveGameUrl(res.url);
                                        router.push({ name: 'play', params: { slug: slug.game_slug, id: slug.provider_id, launchcode: res.launch_code, type: type } });
                                   }
                              }
                         })
                         .catch(() => {
                                closeLaunchConfirmation();
                                errorToast("Something went wrong!", "top-right");
                         });
               } else {
                    router.push({ name: "login" });
               }
          };

          const getSimilarGames = async () => {
               similarGames.value = null;
               const params = { game_name: gamesDetails.value['game_name'], provider_id: provider.value[0]['id'] };
               await gamesSimilarAPI(params)
                    .then((res) => {
                         similarGames.value = res
                         // console.log(similarGames.value);
                    })
                    .catch((e) => {
                         console.error(e);
                    });
          };

          const openLaunchConfirmation = (game_details, type) => {
               if(open_kyc_game_launcher() == true) {
                    return;
               };
               activeGameDetails.value = game_details;
               activeType.value = type;
               eventBus.emit("open:launchconfirmation");
          };

          const closeLaunchConfirmation = () => {
               eventBus.emit("close:launchconfirmation");
          };

          const checkActionRestricted = () => {
               // PLAY
               const restrictedActionType = 3;

               return isActionRestricted(restrictedActionType)
          }

          onMounted(async () => {
               scrollTop();
               await useGamesStore.getProvider();
               await getGame();
               await getSimilarGames();
               await getHotGames();
          });

          return {
               gamesDetails,
               hotGames,
               goToDetail,
               loading,
               providerData,
               provider,
               isUser,
               onLaunch,
               similarGames,
               baseUrl,
               openLaunchConfirmation,
               activeGameDetails,
               activeType,
               checkActionRestricted
          };
     },
};
</script>
