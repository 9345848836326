import request from '@/utils/request';

export function logResponse(body, headers) {
  return request({
    url: '/apple-pay/log-response',
    method: 'post',
    data: body,
    headers: headers
  });
}

export function authorizeMerchant(body, headers) {
  return request({
    url: '/apple-pay/authorize-merchant',
    method: 'post',
    data: body,
    headers: headers
  });
}

export function payment(body, headers) {
  return request({
    url: '/apple-pay/payment',
    method: 'post',
    data: body,
    headers: headers
  });
}
