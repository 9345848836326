import PageNotFound from 'pages/404/index.vue'
import Home from 'pages/home/index.vue'
import Login from 'pages/auth/login.vue'
import Register from 'pages/auth/register.vue'
import Forgot from 'pages/auth/forgot.vue'
import Promotion from 'pages/promotion/index.vue'
import PromotionDetail from 'pages/promotionDetail/index.vue'
import Games from 'pages/games/index.vue'
import Provider from 'pages/provider/index.vue'
import Support from 'pages/support/index.vue'
import Redeem from 'pages/redeem/index.vue'
import Code from 'pages/code/index.vue'
import About from 'pages/about/index.vue'
// import Request from 'pages/request/index.vue'
import ResetPassword from 'pages/reset/index.vue'
import Terms from 'pages/tnc/index.vue'
import MooziRules from 'pages/mooziRules/index.vue'
import PlayerSafety from 'pages/playerSafety/index.vue'
import Sweepstakes from 'pages/sweeptakes/index.vue'
import Privacy from 'pages/privacy/index.vue'
import GoldCoinShop from 'pages/goldCoinShop/index.vue'
import PaymentDetails from 'pages/paymentDetails/index.vue'
import Notification from 'pages/notification/index.vue';
import Account from 'pages/account/index.vue';
import Thanks from 'pages/thanks/index.vue';
import GameDetails from 'pages/gameDetails/index.vue';
import Play from 'pages/play/index.vue';

import auth from 'middleware/auth.js';
import guest from 'middleware/guest.js';

const routes = [
     {
          meta: {
               layout: 'basic',
          },
          path: "/:pathMatch(.*)*",
          name: "NotFound",
          component: PageNotFound
     },
     {
          meta: {
               layout: 'default',
          },
          path: "/",
          name: "home",
          component: Home,
     },
     {
          meta: {
               layout: 'default',
               middleware: guest,
          },
          path: "/signin",
          name: "login",
          component: Login,
     },
     {
          meta: {
               layout: 'default',
               middleware: guest,
          },
          path: "/signup",
          name: "register",
          component: Register,
     },
     {
          meta: {
               layout: 'default',
               middleware: guest,
          },
          path: "/forgot",
          name: "forgot",
          component: Forgot,
     },
     // {
     //      meta: {
     //           layout: 'default',
     //      },
     //      path: "/home",
     //      name: "home",
     //      component: Home,
     // },
     {
          meta: {
               layout: 'default',
          },
          path: "/promotion",
          name: "promotion",
          component: Promotion,
     },
     {
          meta: {
               layout: 'default',
          },
          path: "/promotion/detail/:title",
          name: "promotionDetail",
          component: PromotionDetail,
     },
     {
          meta: {
               layout: 'default'
          },
          path: "/games/:type?/:value?",
          name: "games",
          component: Games
     },
     {
          meta: {
               layout: 'default'
          },
          path: "/games/detail/:id?",
          name: "gameDetails",
          component: GameDetails
     },
     {
          meta: {
               layout: 'default'
          },
          path: "/provider/:name",
          name: "provider",
          component: Provider
     },
     {
          meta: {
               layout: 'default'
          },
          path: "/support",
          name: "support",
          component: Support
     },
     {
          meta: {
               layout: 'default',
               middleware: auth,
          },
          path: "/code",
          name: "code",
          component: Code
     },
     {
          meta: {
               layout: 'default'
          },
          path: "/about-us",
          name: "about-us",
          component: About
     },
     // {
     //      meta: {
     //           layout: 'default'
     //      },
     //      path: "/submit-a-request",
     //      name: "submit-a-request",
     //      component: Request
     // },
     {
          meta: {
               layout: 'default',
          },
          path: "/reset/:token?",
          name: "reset",
          component: ResetPassword
     },
     {
          meta: {
               layout: 'default'
          },
          path: "/terms-of-use",
          name: "terms-of-use",
          component: Terms
     },
     {
        meta: {
             layout: 'default'
        },
        path: "/moozi-rules",
        name: "moozi-rules",
        component: MooziRules
   },
     {
          meta: {
               layout: 'default'
          },
          path: "/player-safety",
          name: "player-safety",
          component: PlayerSafety
     },
     {
          meta: {
               layout: 'default'
          },
          path: "/sc-coins",
          name: "sc-coins",
          component: Sweepstakes
     },
     {
          meta: {
               layout: 'default'
          },
          path: "/privacy",
          name: "privacy",
          component: Privacy
     },
     {
          meta: {
               layout: 'default',
               middleware: auth,
          },
          path: "/gold-coin-shop",
          name: "gold-coin-shop",
          component: GoldCoinShop
     },
     {
          meta: {
               layout: 'default',
               middleware: auth,
          },
          path: "/payment/:id?",
          name: "payment",
          component: PaymentDetails
     },
     {
          meta: {
               layout: 'default',
               middleware: auth,
          },
          path: "/redeem",
          name: "redeem",
          component: Redeem
     },
     {
          meta: {
               layout: 'default',
               middleware: auth,
          },
          path: "/notification",
          name: "notification",
          component: Notification
     },
     {
          meta: {
               layout: 'default',
               middleware: auth,
          },
          path: "/account",
          name: "account",
          component: Account
     },
     {
        meta: {
            layout: 'play',
            middleware: auth,
        },
        path: "/play/:slug/:id/:launchcode/:type",
        name: "play",
        component: Play,
        props: true
    },
     {
          meta: {
               layout: 'default',
          },
          path: "/thanks/:gc/:sc",
          name: "thanks",
          component: Thanks,
          props: true
     },
];

export default routes;
