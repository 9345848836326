<template>
     <div class="flex flex-col justify-between pb-4 ">
          <div v-if="tableLoading" class="w-full flex items-center justify-center" style="height:555px">
               <!-- <loading-small :isLoading="tableLoading" /> -->
               <svg aria-hidden="true" class="w-8 h-8 text-primal-black animate-spin fill-primal-yellow"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                         fill="currentColor"></path>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                         fill="currentFill"></path>
               </svg>
          </div>
          <div v-if="!tableLoading"
               class=" py-1 px-0 xs:py-0 custom-flex-style billing overflow-x-auto hidden lg:block px-5 md:px-7 lg:px-10 2xl:px-36"
               :style="tableLoading ? 'height:90%' : 'max-height:90%'">
               <table class="h-full font-roboto border-collapse border-spacing-4 table-auto w-full whitespace-nowrap"
                    id="billing-table">
                    <thead>
                         <tr class="text-gray-10">
                              <!-- <th class="text-left px-1 md:px-8 py-2 custom-flex-header text-xs xs:text-sm font-normal">
                                   ID
                              </th> -->
                              <th
                                   class="text-left px-1 lg:px-6 xl:px-8 py-2 custom-flex-header text-xs xs:text-sm font-normal">
                                   Date
                              </th>

                              <th
                                   class="text-left px-1 lg:px-6 xl:px-8 py-2 custom-flex-header text-xs xs:text-sm font-normal">
                                   Transaction
                              </th>
                              <th
                                   class="text-center px-1 lg:px-6 xl:px-8 py-2 custom-flex-header text-xs xs:text-sm font-normal">
                                   Amount
                              </th>
                              <th
                                   class="text-left px-1 lg:px-6 xl:px-8 py-2 custom-flex-header text-xs xs:text-sm font-normal">
                                   Status
                              </th>
                         </tr>
                    </thead>
                    <tbody class="text-xs xs:text-sm">
                         <tr v-if="transaction_data.length == 0" class="custom-flex-items">
                              <td colspan="5" class="text-center p-2">
                                   No transaction found.
                              </td>
                         </tr>
                         <tr v-for="(data, index) in transaction_data" class="custom-flex-items border-2 rounded-md"
                              :class="index % 2 == 0 ? 'border-gray-180' : 'border-none'" :key="index">
                              <!-- <td class="text-xs" :class="index % 2 == 0 ? 'px-1 md:px-8 py-2 ' : 'pt-2'">{{ data.id }}</td> -->
                              <td class="text-xs" :class="index % 2 == 0 ? 'px-1 lg:px-6 xl:px-8 py-2 ' : 'pt-2'">{{
                                   data.created_at ?
                                        date8(new Date(data.created_at)) : '' }}</td>

                              <td class="pl-1 lg:pl-6 xl:pl-8 text-xs" :class="index % 2 == 0 ? '' : 'hidden'">
                                   {{
                                        data.transaction_type == 1
                                             ? (data.provider == 'MANUAL' ? "Manual Deposit" : "Purchase")
                                             : data.transaction_type == 2
                                                  ? "Withdraw"
                                                  : data.transaction_type == 3
                                                       ? "Bonus"
                                                       : "Deposit Adjustment"
                                   }}
                              </td>
                              <td :class="index % 2 == 0 ? '' : 'hidden'">
                                   <div class="pr-4 flex item-center justify-end font-bold text-right space-x-5">
                                        <div class="flex items-center space-x-0.5 w-full"
                                             :class="data.transaction_type == 3 && JSON.parse(data.conversion).gold_coins ? 'justify-end' : 'justify-center'">
                                             <img :class="data.transaction_type == 3 || (data.transaction_type == 1 && data.provider == 'MANUAL')
                                                  ? 'block h-4'
                                                  : 'hidden'
                                                  " :src="baseUrl.img + '/assets/img/header/GC.svg'" alt="" />
                                             <div>
                                                  {{
                                                       data.transaction_type == 3
                                                            ? (
                                                                 JSON.parse(data.conversion).gold_coins ?
                                                                      money(JSON.parse(data.conversion).gold_coins)
                                                                      :
                                                                      money(JSON.parse(JSON.parse(data.conversion)).amount))
                                                            : (
                                                                 data.transaction_type == 1 && data.provider == 'MANUAL' ?
                                                                      money(JSON.parse(data.conversion).gold_coins)
                                                                      : (
                                                                           data.transaction_type == 4 ?
                                                                                "- $" + money(JSON.parse(data.conversion).silver_coins)
                                                                                : (data.transaction_type == 2 ? '-' : '+') + "$" +
                                                                                money(data.net_amount)
                                                                      )
                                                            )
                                                  }}
                                             </div>
                                        </div>
                                        <div class="flex items-center justify-end space-x-0.5"
                                             :class="(data.transaction_type == 3 && JSON.parse(data.conversion).silver_coins) || (data.transaction_type == 1 && data.provider == 'MANUAL') ? '' : 'hidden'">
                                             <img :class="data.transaction_type == 3 || (data.transaction_type == 1 && data.provider == 'MANUAL')
                                                  ? 'block h-4'
                                                  : 'hidden'
                                                  " :src="baseUrl.img + '/assets/img/header/SC.svg'" alt="" />
                                             <div>
                                                  {{
                                                       data.transaction_type == 3 || (data.transaction_type == 1 &&
                                                            data.provider == 'MANUAL')
                                                            ? money(
                                                                 JSON.parse(data.conversion).silver_coins
                                                            )
                                                            : "$" + money(data.net_amount)
                                                  }}
                                             </div>
                                        </div>
                                   </div>
                              </td>
                              <td :class="index % 2 == 0 ? '' : 'hidden'">
                                   <div class="text-xs px-1 lg:px-6 xl:px-8 py-2 flex items-center">
                                        <div :class="data.payment_status == 0
                                             ? 'bg-primal-yellow'
                                             : data.payment_status == 1
                                                  ? 'bg-primal-green'
                                                  : 'bg-primal-red'
                                             " class="rounded-full w-2 h-2 mr-2"></div>
                                        <span>{{
                                             data.payment_status == 0
                                                  ? "Pending"
                                                  : data.payment_status == 1
                                                       ? "Success"
                                                       : "Failed"
                                        }}</span>
                                   </div>
                              </td>
                         </tr>
                    </tbody>
               </table>
          </div>
          <div v-if="!tableLoading" class=" py-1 px-0 xs:py-0 custom-flex-style billing overflow-x-auto block lg:hidden"
               :style="tableLoading ? 'height:90%' : 'max-height:90%'">
               <div v-if="transaction_data.length == 0" class="w-full h-full flex items-center justify-center">
                    No transaction found.
               </div>
               <div v-else>
                    <div v-if="transaction_mobile">
                         <div v-for="(data, index) in Object.keys(transaction_mobile)" :key="index">
                              <div class="bg-shade-6">
                                   <div class="px-5 py-4 font-bold text-shade-3">
                                        {{ data }}
                                   </div>
                                   <div v-for="(item, key) in transaction_mobile[data]" :key="key">
                                        <div class="px-5 py-2 bg-white !border-b-background-5"
                                             style="border-bottom: 1px solid;">
                                             <div
                                                  class="text-gray-10 font-roboto text-xs xs:text-sm flex flex-row justify-between">
                                                  <div>
                                                       {{ item.time }}
                                                  </div>
                                                  <div class="px-1 lg:pl-6 xl:pl-8 flex items-center">
                                                       <div :class="item.payment_status == 0
                                                            ? 'bg-primal-yellow'
                                                            : item.payment_status == 1
                                                                 ? 'bg-primal-green'
                                                                 : 'bg-primal-red'
                                                            " class="rounded-full w-2 h-2 mr-2"></div>
                                                       <span>{{
                                                            item.payment_status == 0
                                                                 ? "Pending"
                                                                 : item.payment_status == 1
                                                                      ? "Success"
                                                                      : "Failed"
                                                       }}</span>
                                                  </div>
                                             </div>
                                             <div class="flex flex-row justify-between">
                                                  <div class="font-bold">
                                                       {{ item.transaction_type == 1
                                                            ? (item.provider == 'MANUAL' ? "Manual Deposit" : "Purchase")
                                                            : item.transaction_type == 2
                                                                 ? "Withdraw"
                                                                 : item.transaction_type == 3
                                                                      ? "Bonus"
                                                                      : "Deposit Adjustment" }}
                                                  </div>
                                                  <div class="flex flex-row gap-5 font-bold">
                                                       <div class="flex items-center space-x-0.5 w-full"
                                                            :class="item.transaction_type == 3 && JSON.parse(item.conversion).gold_coins ? 'justify-end' : 'justify-center'">
                                                            <img :class="item.transaction_type == 3 || (item.transaction_type == 1 && item.provider == 'MANUAL')
                                                                 ? 'block h-4'
                                                                 : 'hidden'
                                                                 " :src="baseUrl.img + '/assets/img/header/GC.svg'"
                                                                 alt="" />
                                                            <div>
                                                                 {{
                                                                      item.transaction_type == 3
                                                                           ? (
                                                                                JSON.parse(item.conversion).gold_coins ?
                                                                                     money(JSON.parse(item.conversion).gold_coins)
                                                                                     :
                                                                                     money(JSON.parse(JSON.parse(item.conversion)).amount))
                                                                           : (
                                                                                item.transaction_type == 1 && item.provider == 'MANUAL'
                                                                                     ?
                                                                                     money(JSON.parse(item.conversion).gold_coins)
                                                                                     : (
                                                                                          item.transaction_type == 4 ?
                                                                                               "- $" + money(JSON.parse(item.conversion).silver_coins)
                                                                                               : (item.transaction_type == 2 ? '-' : '+') + "$" +
                                                                                               money(item.net_amount)
                                                                                     )
                                                                           )
                                                                 }}
                                                            </div>
                                                       </div>
                                                       <div class="flex items-center justify-end space-x-0.5"
                                                            :class="(item.transaction_type == 3 && JSON.parse(item.conversion).silver_coins) || (item.transaction_type == 1 && item.provider == 'MANUAL') ? '' : 'hidden'">
                                                            <img :class="item.transaction_type == 3 || (item.transaction_type == 1 && item.provider == 'MANUAL')
                                                                 ? 'block h-4'
                                                                 : 'hidden'
                                                                 " :src="baseUrl.img + '/assets/img/header/SC.svg'"
                                                                 alt="" />
                                                            <div>
                                                                 {{
                                                                      item.transaction_type == 3 || (item.transaction_type ==
                                                                           1 &&
                                                                           item.provider == 'MANUAL')
                                                                           ? money(
                                                                                JSON.parse(item.conversion).silver_coins
                                                                           )
                                                                           : "$" + money(item.net_amount)
                                                                 }}
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
          <div class="mt-12 xl:mt-4 flex justify-center px-5 md:px-7 lg:px-10 2xl:px-36">
               <div v-if="pagination.totalPage" class="flex w-full justify-center items-center gap-2 md:gap-7 mt-5">
                    <button class="prev p-2 md:p-3 xl:p-4 rounded-full"
                         :class="pagination.currentPage > 2 ? 'scale-100 hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer text-primary-1 bg-secondary-3' : 'hidden cursor-not-allowed bg-primary-4'"
                         @click="onFirstPage" :disabled="pagination.currentPage < 2">
                         <svg class="w-3 h-3" :class="pagination.currentPage > 2 ? '' : 'opacity-20'"
                              viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8 2.00391L2 8.00391L8 14.0039" stroke="black" stroke-width="2"
                                   stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M13 2.00391L7 8.00391L13 14.0039" stroke="black" stroke-width="2"
                                   stroke-linecap="round" stroke-linejoin="round" />
                         </svg>
                    </button>
                    <button class="prev p-2 md:p-3 xl:p-4 rounded-full"
                         :class="pagination.currentPage != 1 ? 'scale-100 hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer text-primary-1 bg-secondary-3' : 'hidden cursor-not-allowed bg-primary-4'"
                         @click="onPrevious" :disabled="pagination.currentPage == 1">
                         <svg class="w-3 h-3" :class="pagination.currentPage != 1 ? '' : 'opacity-20'"
                              viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11 1.99805L5 7.99805L11 13.998" stroke="black" stroke-width="2"
                                   stroke-linecap="round" stroke-linejoin="round" />
                         </svg>
                    </button>
                    <div class="font-medium p-2 md:p-3 xl:p-4 rounded-full flex justify-center items-center cursor-pointer scale-100 hover:scale-105 transition-transform duration-300 ease-in-out"
                         :class="pagination.currentPage == 1 ? 'text-secondary-3 underline underline-offset-4' : 'text-primary-1'"
                         @click="onFirstBtn">
                         <span class="text-lg leading-5 text-center w-4 h-4">
                              {{ pagination.currentPage == 1 ? 1 : (pagination.currentPage == pagination.totalPage &&
                                   pagination.totalPage < 2) || (pagination.currentPage == pagination.totalPage &&
                                        pagination.currentPage != 2) ? pagination.currentPage - 2 : pagination.currentPage - 1
                                   }} </span>
                    </div>
                    <div v-if="pagination.totalPage != 1"
                         class="font-medium p-2 md:p-3 xl:p-4 rounded-full flex justify-center items-center cursor-pointer scale-100 hover:scale-105 transition-transform duration-300 ease-in-out"
                         :class="(pagination.currentPage != 1 && pagination.currentPage != pagination.totalPage) || (pagination.currentPage == 2 && pagination.currentPage == pagination.totalPage) ? 'text-secondary-3 underline underline-offset-4' : 'text-primary-1'"
                         @click="onSecondBtn">
                         <span class="text-lg leading-5 text-center w-4 h-4">
                              {{ pagination.currentPage == 1 ? pagination.currentPage + 1 : ((pagination.currentPage ==
                                   pagination.totalPage && pagination.totalPage < 2) ||
                                   (pagination.currentPage == pagination.totalPage && pagination.currentPage != 2) ?
                                   pagination.currentPage - 1 : pagination.currentPage) }} </span>
                    </div>
                    <div v-if="pagination.totalPage != 1 && pagination.totalPage != 2"
                         class="font-medium p-2 md:p-3 xl:p-4 rounded-full flex justify-center items-center cursor-pointer scale-100 hover:scale-105 transition-transform duration-300 ease-in-out"
                         :class="pagination.currentPage == 1 ? 'text-primary-1' : pagination.currentPage == pagination.totalPage ? 'text-secondary-3 underline underline-offset-4' : 'text-primary-1'"
                         @click="onThirdBtn">
                         <span class="text-lg leading-5 text-center w-4 h-4 cursor-pointer">
                              {{ pagination.currentPage == 1 ? pagination.currentPage + 2 : pagination.currentPage ==
                                   pagination.totalPage ? pagination.currentPage : pagination.currentPage + 1 }}
                         </span>
                    </div>
                    <button class="p-2 md:p-3 xl:p-4 rounded-full"
                         :class="pagination.currentPage != pagination.totalPage ? 'scale-100 hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer text-primary-1 bg-secondary-3' : 'hidden cursor-not-allowed bg-primary-4'"
                         @click="onNext" :disabled="pagination.currentPage == pagination.totalPage">
                         <svg class="w-3 h-3"
                              :class="pagination.currentPage != pagination.totalPage ? '' : 'opacity-20'"
                              viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5 14L11 8L5 2" stroke="#422C18" stroke-width="2" stroke-linecap="round"
                                   stroke-linejoin="round" />
                         </svg>
                    </button>
                    <button class="p-2 md:p-3 xl:p-4 rounded-full"
                         :class="pagination.currentPage != pagination.totalPage ? 'scale-100 hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer text-primary-1 bg-secondary-3' : 'hidden cursor-not-allowed bg-primary-4'"
                         @click="onLastPage" :disabled="pagination.currentPage == pagination.totalPage">
                         <svg class="w-3 h-3"
                              :class="pagination.currentPage != pagination.totalPage ? '' : 'opacity-20'"
                              viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8 14L14 8L8 2" stroke="#422C18" stroke-width="2" stroke-linecap="round"
                                   stroke-linejoin="round" />
                              <path d="M3 14L9 8L3 2" stroke="#422C18" stroke-width="2" stroke-linecap="round"
                                   stroke-linejoin="round" />
                         </svg>
                    </button>
               </div>
          </div>
     </div>
</template>
<script>
import { useFormatter } from "composables/use-formatter.js";
import { baseUrl } from "config/services.js";
import { onMounted, ref } from "vue";
import { toRefs } from "vue";
import { watch } from "vue";
import _ from "underscore";

export default {
     props: [
          "tableLoading",
          "transaction_data",
          'transaction',
          "pagination",
          "onFirstPage",
          "onPrevious",
          "onFirstBtn",
          "onSecondBtn",
          "onThirdBtn",
          "onNext",
          "onLastPage"
     ],
     setup(props) {
          const { date8, date6, time, money, eighteenYearsAgo } = useFormatter();

          const { transaction_data } = toRefs(props);

          const transaction_mobile = ref(null)

          const parseToMobile = (newVal) => {
               const filter = _.filter(newVal, function (params) { return params != ""; })

               const map = _.map(filter, function (params) {
                    return { ...params, 'date': date6(new Date(params['created_at'])), 'time': time(new Date(params['created_at'])) }
               });

               const group = _.groupBy(map, 'date');

               transaction_mobile.value = group
          }

          onMounted(() => {
               if (transaction_data.value) {
                    parseToMobile(transaction_data.value)
               }
          })

          watch(transaction_data, (newVal) => {
               if (newVal) {
                    if (newVal.length != 0) {
                         parseToMobile(newVal)
                    } else {
                         transaction_mobile.value = [];
                    }
               } else {
                    transaction_mobile.value = null
               }
          });

          return {
               date8,
               money,
               eighteenYearsAgo,
               baseUrl,
               transaction_mobile
          };
     },
};
</script>
