import request from 'utils/request.js';

export function getCaptcha() {
  return request({
    url: '/captcha',
    method: 'get',
  });
}

export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data: data,
  });
}

export function register(data) {
  return request({
    url: '/register',
    method: 'post',
    data: data,
  });
}

export function getUser() {
  return request({
    url: '/user',
    method: 'get',
  });
}

export function getUniqueCode(data) {
  return request({
    url: '/user/unique_code',
    method: 'post',
    data: data
  });
}

export function uniqueCodeStatus() {
  return request({
    url: '/user/unique_code_status',
    method: 'post',
  });
}

export function logout() {
  return request({
    url: 'user/logout',
    method: 'post',
  });
}

export function forgotPassword(data) {
  return request({
    url: '/password/reset',
    method: 'post',
    data: data,
  });
}

export function updatePassword(data) {
  return request({
    url: '/password/update',
    method: 'post',
    data: data,
  });
}

export function emailVerification(data) {
  return request({
    url: '/verify/'+data,
    method: 'get',
  });
}

export function emailVerificationResend(data) {
  return request({
    url: '/verification/resend',
    method: 'post',
    data: data,
  });
}

export function profileUpdate(data) {
  return request({
    url: '/user/update',
    method: 'post',
    data: data,
  });
}

export function uploadIdKyc(data) {
  return request({
    url: '/user/kyc/upload',
    method: 'get',
    data: data,
  });
}

export function getKYC() {
  return request({
    url: '/user/kyc/token',
    method: 'get',
  });
}

export function setApplicantID(data) {
  return request({
    url: '/user/kyc/applicant',
    method: 'post',
    data: data,
  });
}

export function getLoginAttempts() {
  return request({
    url: '/login_attempt',
    method: 'get',
  });
}
export function reloadBalance(data) {
  return request({
    url: '/user/balance/reload',
    method: 'get',
    params: data,
  });
}
// export function authToken(data) {
//   return request({
//     url: '/user/validate',
//     method: 'post',
//     data: data,
//   });
// }

// export function newPassword(data) {
//   return request({
//     url: 'user/new-password',
//     method: 'post',
//     data: data,
//   });
// }

export function changePassword(data) {
  return request({
    url: 'user/update/password',
    method: 'post',
    data: data,
  });
}

export function getSocialLinked() {
  return request({
    url: 'user/socials',
    method: 'post',
  });
}

export function setSocialLinked(data) {
  return request({
    url: 'user/social_linked',
    method: 'post',
    data: data,
  });
}

export function deleteCard(data) {
  return request({
    url: 'user/delete_card',
    method: 'post',
    data: data,
  });
}

export function deleteAccount(data) {
  return request({
    url: 'user/delete',
    method: 'post',
    data: data,
  });
}

export function updateReminder(data) {
  return request({
    url: 'user/update_reminder',
    method: 'post',
    data: data,
  });
}

export function resetReminder() {
  return request({
    url: 'user/reset_reminder',
    method: 'post',
  });
}

export function transactionLimit(data) {
  return request({
    url: 'user/transaction_limit',
    method: 'post',
    data: data,
  });
}

export function limitReach() {
  return request({
    url: 'user/limit_reach',
    method: 'get'
  });
}

export function exclusion(data) {
  return request({
    url: 'user/exclusion',
    method: 'post',
    data: data,
  });
}

export function kycStatus(data) {
  return request({
    url: 'user/kyc/status',
    method: 'get',
    params: data,
  });
}

export function getReferralCode() {
  return request({
    url: '/user/referral_code',
    method: 'get'
  });
}

export function getGameToken(data) {
    return request({
      url: '/user/gametoken',
      method: 'get',
      params: data,
    });
}
