export const useImage = () => {
    const sizeArray = {
        'games': {
            'md': {
                'width': 273,
                'height': 243,
            },
            'sm': {
                'width': 195,
                'height': 174,
            },
            'xs': {
                'width': 173,
                'height': 153,
            }
        },
        'popular': {
            'md': {
                'width': 226,
                'height': 234,
            },
            'sm': {
                'width': 430,
                'height': 400,
            },
            'xs': {
                'width': 329,
                'height': 324,
            }
        },
    }

    const toWebp = (value, type) => {
        const ext = [".jpeg", ".png", ".jpg"];
        let size = '';

        const regex = new RegExp('\\b(' + ext.join('|') + ')\\b', 'i');
        if(regex.test(value)) {
            if(type) {
                if(window.innerWidth >= 1024) {
                    size = 'lg'
                }
                else if(window.innerWidth >= 768 && window.innerWidth <= 1023) {
                    size = 'md'
                } else if (window.innerWidth >= 640 && window.innerWidth <= 767) {
                    size = 'sm'
                } else {
                    size = 'xs'
                }

                if(size == 'lg') {
                    return 'https://moozi.com/api/utils_image?url='+value;
                }
                
                const getSize = sizeArray[type][size];

                return 'https://moozi.com/api/utils_image?url='+value+'&width='+getSize['width']
            } else {
                return 'https://moozi.com/api/utils_image?url='+value;
            }
        }
        return value;
    };

    return { toWebp };
};
