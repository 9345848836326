<template>
     <div
          v-for="(item, key) in pagination?.data"
          :key="key"
          class="game-card rounded-xl bg-primary-1 w-full h-max relative p-1 "
          @click="() => goToDetail(item.game_name, item.id)"
     >
          <div
               v-if="isUser"
               class="absolute top-2 right-2 rounded-xl z-[8]"
          >
               <span
                    @click="(e) => {
                         e.stopPropagation();
                         e.preventDefault();
                         onStar(item.id, heartData?.includes(item.id))
                    }"
                    class="text-2xl md:text-3xl icon-addFavorites cursor-pointer"
                    :class="heartData?.includes(item.id) ? 'active' : ''"
               ></span>
          </div>
          <div
               v-if="isUser"
               class="game-card-overlay absolute top-0 left-0 rounded-xl cursor-pointer"
               :class="deviceType == 'mobile' ? 'hidden' : ''"
          >
               <div class="flex flex-col justify-center items-center h-full">
                    <transition enter-from-class="opacity-0" enter-active-class="transition ease-in duration-300"
                         enter-to-class="opacity-100 scale-100">
                         <div 
                              class="px-3"
                              v-if="checkActionRestricted()"
                         >
                              <div
                                   class="rounded-xl !mt-0 bg-primal-yellow text-shade-19 flex flex-row items-center gap-2 ease-in-out transition-all duration-300 p-3 mb-4 w-full">
                                   <p class="text-sm lg:text-base font-medium">
                                        This action is currently restricted pls contact support!
                                   </p>
                              </div>
                         </div>
                    </transition>
                    <div
                         v-if="!checkActionRestricted()"
                         @click="(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              onLaunch(item, 'gold')
                         }"
                         class="bg-background-2 w-10/12 sm:w-9/12 md:w-10/12 xl:w-11/12 2xl:w-9/12 h-7 xs:h-8 sm:h-10 md:h-8 lg:h-9 xl:h-10 2xl:h-12 rounded-full relative cursor-pointer"
                    >
                         <div class="flex items-center justify-center tracking-wider h-full">
                              <img
                                   loading="lazy"
                                   class="cursor-pointer w-7 xs:w-8 sm:w-10 md:w-8 lg:w-9 xl:w-10 2xl:w-12 h-7 xs:h-8 sm:h-10 md:h-8 lg:h-9 xl:h-10 2xl:h-12 absolute top-0 left-0"
                                   :src="baseUrl.img+'/assets/img/header/GC.svg'"
                                   alt=""
                              >
                              <span class="ml-5 sm:ml-8 2xl:ml-10 text-white text-mid md:text-xs xl:text-sm">{{ money(gold_balance) }}</span>
                         </div>
                    </div>
                    <div
                         v-if="!checkActionRestricted()"
                         @click="(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              onLaunch(item, 'silver')
                         }"
                         class="bg-background-2 w-10/12 sm:w-9/12 md:w-10/12 xl:w-11/12 2xl:w-9/12 h-7 xs:h-8 sm:h-10 md:h-8 lg:h-9 xl:h-10 2xl:h-12 rounded-full relative mt-3 sm:mt-5 md:mt-3 2xl:mt-5 cursor-pointer"
                    >
                         <div class="flex items-center justify-center tracking-wider h-full">
                              <img
                                   loading="lazy"
                                   class="cursor-pointer w-7 xs:w-8 sm:w-10 md:w-8 lg:w-9 xl:w-10 2xl:w-12 h-7 xs:h-8 sm:h-10 md:h-8 lg:h-9 xl:h-10 2xl:h-12 absolute top-0 left-0"
                                   :src="baseUrl.img+'/assets/img/header/SC.svg'"
                                   alt=""
                              >
                              <span class="ml-5 sm:ml-8 2xl:ml-10 text-white text-mid md:text-xs xl:text-sm">{{ money(silver_balance) }}</span>
                         </div>
                    </div>
               </div>
          </div>
          <div class="games-aspect-ratio-3-4 relative items-center cursor-pointer">
               <img
                    width="100"
                    height="100"
                    loading="lazy"
                    :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                    alt="game"
                    class="absolute top-0 left-0 rounded-t-md w-full h-full game-thumbnails"
               >
               <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                    <img
                         width="100"
                         height="100"
                         loading="lazy"
                         v-if="item.game_pic != null"
                         :src="toWebp(item.game_pic, 'games')"
                         :alt="getProvider(item.provider_id) +'-'+ item.game_name"
                         class="rounded-t-md w-full hidden md:block game-thumbnails"
                         style="height: inherit;"
                    >
                    <img
                         width="100"
                         height="100"
                         loading="lazy"
                         v-else
                         :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                         alt="game"
                         class="rounded-t-md w-full h-auto hidden md:block game-thumbnails"
                    >
                    <img
                         width="100"
                         height="100"
                         loading="lazy"
                         v-if="item.game_pic_mobile != null"
                         :src="toWebp(item.game_pic_mobile, 'games')"
                         :alt="getProvider(item.provider_id) +'-'+ item.game_pic"
                         class="rounded-t-md w-full h-auto block md:hidden game-thumbnails"
                         style="height: inherit;"
                    >
                    <img
                         width="100"
                         height="100"
                         loading="lazy"
                         v-else
                         :src="baseUrl.img+'/assets/img/loader/popular-loader.png'"
                         alt="game"
                         class="rounded-t-md w-full h-auto block md:hidden game-thumbnails"
                    >
               </div>
          </div>
          <div class="flex justify-between items-center w-full px-4 py-4">
               <div class="game-name">
                    <p class="helveticaLight tracking-wide text-secondary-1 text-sm md:text-base xl:text-lg">
                         {{item.game_name}}
                    </p>
               </div>
               <img
                    loading="lazy"
                    :src="baseUrl.img+'/assets/img/home/game/moozi.webp'"
                    alt="moozi"
                    class="rounded-md w-6 md:w-8 xl:w-11 h-6 md:h-8 xl:h-11"
               >
          </div>
     </div>
</template>
<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { gamesStore } from "store/games";
import { useImage } from "composables/use-image.js";
import { authStore } from "store/auth.js";
import { useFormatter } from "composables/use-formatter.js";

import _ from "underscore";
import { baseUrl } from "config/services.js";
import device from 'middleware/device.js';
import { useActionRestriction } from 'composables/use-restriction.js';

export default {
     props: ['pagination', 'onStar', 'onLaunch', 'isUser', 'provider'],
     components: {
     },
     setup(props) {
          const router = useRouter();
          const { toWebp } = useImage();
          const { money } = useFormatter();

          const useAuthStore = authStore();
          const useGamesStore = gamesStore();
          const { isActionRestricted } = useActionRestriction();
          
          const { deviceType } = device();

          const heartData = computed(() => {
               return useGamesStore.heart || null;
          });

          const gold_balance = computed(() => {
               return useAuthStore.gold_balance;
          });

          const silver_balance = computed(() => {
               return parseFloat(useAuthStore.silver_balance) + parseFloat(useAuthStore.silver_balance_redeem);
          });

          const getProvider = (id) => {
               const filter = _.filter(props.provider, (params) => {
                    return params['id'] == id
               })

               return filter[0]['name']
          }

          const goToDetail = (name, id) => {
               router.push({
                    path:
                         "/games/detail/" +
                         encodeURIComponent(name.replaceAll(" ", "-")) +
                         "-" +
                         id,
               });
          };

          const checkActionRestricted = () => {
               // PLAY
               const restrictedActionType = 3;

               return isActionRestricted(restrictedActionType)
          }

          return {
               heartData,
               goToDetail,
               toWebp,
               money,
               gold_balance,
               silver_balance,
               getProvider,
               baseUrl,
               deviceType,
               checkActionRestricted
          };
     },
};
</script>
