<template>
     <div
          class="w-max lg:w-40 flex h-full cursor-pointer relative"
     >
          <div @click="onSortByOpen()" class="flex flex-row gap-3 justify-center items-center w-full lg:w-full sort-trigger">
               <p class="font-roboto font-extrabold text-white text-xs md:text-xs lg:text-sm xl:text-lg capitalize md:uppercase hidden xl:block sort-trigger-text text-nowrap overflow-hidden">SORT BY</p>
               <span class="icon-arrow-down text-xs sort-trigger-down hidden xl:block"></span>
               <span class="icon-sortby text-3xl md:pr-2 lg:pr-0 block xl:hidden"></span>
          </div>
          <div v-if="sortByOpen" class="absolute z-20 top-12 bg-primary-1 flex flex-col justify-start gap-1 lg:gap-2 rounded-md sort-wrapper w-28 lg:w-32 xl:w-full right-0">
               <div @click="() => onSort('a-z')" class="sort-list flex flex-row gap-3 items-center px-3 py-2">
                    <span :class="pagination.sortType == 'a-z' ? '' : 'invisible'" class="icon-check-stroke text-xxs sort-check"></span>
                    <p class="font-roboto font-normal text-white text-xs md:text-xs lg:text-sm xl:text-base sort-text">Title: A-Z</p>
               </div>
               <div @click="() => onSort('z-a')" class="sort-list flex flex-row gap-3 items-center px-3 py-2">
                    <span :class="pagination.sortType == 'z-a' ? '' : 'invisible'" class="icon-check-stroke text-xxs sort-check"></span>
                    <p class="font-roboto font-normal text-white text-xs md:text-xs lg:text-sm xl:text-base sort-text">Title: Z-A</p>
               </div>
               <div @click="() => onSort('popularity')" class="sort-list flex flex-row gap-3 items-center px-3 py-2">
                    <span :class="pagination.sortType == 'popularity' ? '' : 'invisible'" class="icon-check-stroke text-xxs sort-check"></span>
                    <p class="font-roboto font-normal text-white text-xs md:text-xs lg:text-sm xl:text-base sort-text">Popular</p>
               </div>
          </div>
     </div>
</template>
<script>
import { onMounted } from 'vue';

export default {
     props: ['pagination', 'sortByOpen', 'onSortByOpen', 'onSort'],
     components: {
     },
     setup(props) {

          onMounted(async () => {
               document.addEventListener("click", (evt) => {
                    const sortTrigger1 = document.querySelector(".sort-wrapper");
                    const sortTrigger2 = document.querySelector(".sort-list");
                    const sortTrigger3 = document.querySelector(".sort-check");
                    const sortTrigger4 = document.querySelector(".sort-text");
                    const sortTrigger5 = document.querySelector(".sort-trigger");
                    const sortTrigger6 = document.querySelector(".sort-trigger-text");
                    const sortTrigger7 = document.querySelector(".sort-trigger-down");

                    let targetElSort = evt.target; // clicked element

                    if (targetElSort != null) {
                         do {
                              if (
                                   targetElSort == sortTrigger1 ||
                                   targetElSort == sortTrigger2 ||
                                   targetElSort == sortTrigger3 ||
                                   targetElSort == sortTrigger4 ||
                                   targetElSort == sortTrigger5 ||
                                   targetElSort == sortTrigger6 ||
                                   targetElSort == sortTrigger7
                              ) {
                                   return;
                              }
                              targetElSort = targetElSort.parentNode;
                         } while (targetElSort);
                    }

                    props.onSortByOpen(false)
               });
          })

          return {
          };
     },
};
</script>