import {
  getDaily as getDailyAPI,
  getWelcome as getWelcomeAPI,
  freeGC as freeGCAPI,
  getReferralStats as getReferralStatsAPI
} from "api/bonus.js";
import {defineStore} from "pinia"
import eventBus from "plugins/event.js";
import { authStore } from "store/auth.js";


export const bonusStore = defineStore('bonus', {
  state: () => {
    return {
      dailyBonus: null,
      welcomeBonus: null,
      claimable_daily: false,
      details: null,
      error_msg: null,
      referral_stats: null
    }
  },
  actions: {
    getDaily() {
      const useAuthStore = authStore();

      return new Promise((resolve, reject) => {
        getDailyAPI()
          .then(response => {
            this.$patch((state) => {
              useAuthStore.setEmailVerification(true)
              state.dailyBonus = response[0];
              state.details = state.dailyBonus != null ? JSON.parse(state.dailyBonus.details) : null;
            })
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              if(error.response){
                useAuthStore.setEmailVerification(false)
                state.error_msg = error.response.data;
              }
            });
            reject(error)
          })
      })
    },
    setClaimableDaily(status) {
      this.$patch((state) => {
        state.claimable_daily = status;
      })
    },
    getWelcome() {
      const useAuthStore = authStore();
      
      return new Promise((resolve, reject) => {
        getWelcomeAPI()
          .then(response => {
            this.$patch((state) => {
              useAuthStore.setEmailVerification(true)
              state.welcomeBonus = response[0] != null ? JSON.parse(response[0].details) : null;
            })
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              if(error.response){
                useAuthStore.setEmailVerification(false)
                state.error_msg = error.response.data;
              }
            });
            reject(error)
          })
      })
    },
    claimFreeGC() {
      const useAuthStore = authStore();
      return new Promise((resolve, reject) => {
        freeGCAPI()
          .then(async () => {
            eventBus.emit("open:free_gc_top");
            await useAuthStore.reloadBalance({ type: "gold_coins" });
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              if(error.response){
                state.error_msg = error.response.data;
              }
            });
            reject(error)
          })
      })
    },
    getReferralStats() {
      return new Promise((resolve, reject) => {
        getReferralStatsAPI()
          .then(response => {
            this.$patch((state) => {
              state.referral_stats = response;
            });
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              if(error.response){
                state.error_msg = error.response.data;
              }
            });
            reject(error)
          })
      })
    }
  }
})
