<template>

  <InertiaHead title="Payment Details"></InertiaHead>
  <div class="w-full h-full relative mt-0">
    <div class="z-0 w-full h-100-vh welcome-bg-container bg-background-2 fixed top-0 left-0"></div>
    <div class="w-full bg-transparent relative">
      <div
        class="w-full h-full flex flex-col justify-center items-center px-5 xs:px-8 sm:px-18 md:px-24 lg:px-28 xl:px-32 pb-16 sm:pb-18 md:pb-20 lg:pb-28 xl:pb-32">
        <div
        v-if="coin != null"
          class="bg-gradient-promo-detail rounded-xl flex flex-col lg:flex-row w-full lg:w-11/12 xl:w-9/12 2xl:w-7/12 mt-18 md:mt-20 lg:mt-18">
          <div class="w-full lg:w-7/12 px-5 xs:px-8 lg:px-5 py-5 xs:py-8 sm:py-10 lg:py-3">
            <div class="w-full block lg:hidden">
              <div class="w-full">
                <div class="flex flex-row w-full gap-3 xs:gap-4 md:gap-5">
                  <div
                    class="flex justify-center items-center bg-background-4 rounded-xl w-max h-18 xs:h-20 md:h-24 px-2">
                    <div class="flex flex-col items-center justify-center h-full">
                      <img :src="'/assets/img/shop/'+(packageType?.type != null ? packageType?.type.split(' ')[0].toLowerCase() : 'custom')+'.png'" alt=""
                        :class="coin?.discount != 0 ? 'w-18 xs:w-20 md:w-24 z-20 mt-2 xs:mt-4' : ''" />
                      <div v-if="packageType?.type" class="relative w-full">
                        <div v-if="coin?.discount != 0" class="flex flex-row justify-start items-center">
                          <div class="absolute z-10 right-0 bottom-4 xs:bottom-5 md:bottom-6">
                            <img :src="baseUrl.img+'/assets/img/shop/badge.png'" alt=""
                              class="w-6 xs:w-8 md:w-10 h-6 xs:h-8 md:h-10" />
                            <div
                              class="absolute top-0 w-full h-full flex justify-center items-center text-white font-roboto font-bold text-xxs">
                              -{{ coin?.discount }}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full">
                    <p class="text-white font-roboto font-extrabold tracking-wider text-base xs:text-lg sm:text-xl uppercase">
                      {{packageType?.type ?? 'Custom Amount'}}
                    </p>
                    <p class="text-white font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-lg">
                      {{ getSum(coin?.gold_coins, coin?.gold_coins_free) }} GC <br>
                      + FREE {{ getSum(coin?.silver_coins, coin?.silver_coins_free) }} SC
                    </p>
                    <p
                      class="text-white text-right font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl">
                      ${{ money(coin?.net_amount) }}
                    </p>
                  </div>
                </div>
                <div class="border border-solid border-secondary-8 mt-5 mb-4 xs:mb-5"></div>
                <div class="flex flex-row justify-between">
                  <p
                    class="text-background-4 font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-lg">
                    Subtotal
                  </p>
                  <p
                    class="text-background-4 font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-lg">
                    ${{ money(coin?.net_amount) }}
                  </p>
                </div>
                <div class="flex flex-row justify-between mt-0">
                  <p
                    class="text-background-4 font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-lg">
                    Deals
                  </p>
                  <p
                    class="text-background-4 font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-lg">
                    -{{ coin?.discount }}%
                  </p>
                </div>
                <div class="flex flex-row justify-between mt-3">
                  <p
                    class="text-white font-helveticaLight tracking-wide font-bold text-base xs:text-lg sm:text-xl md:text-2xl">
                    Total
                  </p>
                  <p
                    class="text-white font-helveticaLight tracking-wide font-bold text-base xs:text-lg sm:text-xl md:text-2xl">
                    ${{ getPercentage(coin?.discount, coin?.net_amount) }}
                  </p>
                </div>
                <div class="flex flex-row justify-end mt-2">
                  <p
                    class="text-background-4 font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-lg">
                    ( Including Tax 10% )
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full hidden lg:block">
              <p
                class="text-white font-roboto font-extrabold tracking-wider text-sm sm:text-lg md:text-xl lg:text-lg xl:text-lg uppercase">
                {{packageType?.type ?? 'Custom Amount'}}
              </p>
              <div class="border border-solid border-secondary-8 mt-2 mb-5"></div>
              <div class="flex justify-center items-center">
                <div class="flex justify-center items-center bg-background-4 rounded-xl w-max h-56 px-4">
                  <div class="flex flex-col items-center justify-center h-full">
                    <img :src="'/assets/img/shop/'+(packageType?.type != null ? packageType?.type.split(' ')[0].toLowerCase() : 'custom')+'.png'" alt="" class="xs:w-36 sm:w-52 md:w-56 z-20" />
                    <div v-if="packageType?.type" class="relative w-full">
                      <div v-if="coin?.discount != 0" class="flex flex-row justify-start items-center">
                        <div class="absolute z-10 right-0 bottom-8 xs:bottom-8 sm:bottom-14">
                          <img :src="baseUrl.img+'/assets/img/shop/badge.png'" alt=""
                            class="w-12 xs:w-14 sm:w-18 md:w-24 h-12 xs:h-14 sm:h-18 md:h-24" />
                          <div
                            class="absolute top-0 w-full h-full flex justify-center items-center text-white font-roboto font-bold text-xs xs:text-xs sm:text-lg">
                            -{{ coin?.discount }}%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-row justify-between mt-5">
                <p
                  class="text-white font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-base">
                  {{ getSum(coin?.gold_coins, coin?.gold_coins_free) }} GC <br>
                  + FREE {{ getSum(coin?.silver_coins, coin?.silver_coins_free) }} SC
                </p>
                <p
                  class="text-white font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-base">
                  ${{ money(coin?.net_amount) }}
                </p>
              </div>
              <div class="border border-solid border-secondary-8 mt-2 mb-5"></div>
              <div class="flex flex-row justify-between">
                <p
                  class="text-background-4 font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-base">
                  Subtotal
                </p>
                <p
                  class="text-background-4 font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-base">
                  ${{ money(coin?.net_amount) }}
                </p>
              </div>
              <div class="flex flex-row justify-between mt-2">
                <p
                  class="text-background-4 font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-base">
                  Deals
                </p>
                <p
                  class="text-background-4 font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-base">
                  -{{ coin?.discount }}%
                </p>
              </div>
              <div class="flex flex-row justify-between mt-3">
                <p
                  class="text-white font-helveticaLight tracking-wide font-bold text-sm sm:text-lg md:text-xl lg:text-lg xl:text-2xl">
                  Total
                </p>
                <p
                  class="text-white font-helveticaLight tracking-wide font-bold text-sm sm:text-lg md:text-xl lg:text-lg xl:text-2xl">
                  ${{ getPercentage(coin?.discount, coin?.net_amount) }}
                </p>
              </div>
              <div class="flex flex-row justify-end mt-2">
                <p
                  class="text-background-4 font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl lg:text-lg xl:text-base">
                  ( Including Tax 10% )
                </p>
              </div>
            </div>
          </div>

          <div
            class="bg-white w-full px-5 md:px-7 lg:px-10 py-5 lg:py-7 flex flex-col justify-start rounded-xl rounded-t-none lg:rounded-l-none lg:rounded-r-xl">

            <div id="appl-btn-area" class="bg-white w-full px-5 md:px-7 lg:px-10 py-5 lg:py-7 flex flex-col justify-center items-center">
              <apple-pay-button buttonstyle="black" type="pay" locale="en-US"></apple-pay-button>
            </div>

            <div class="flex flex-row gap-2">
              <div
                @click="tabs = 'credit'"
                class="hover:bg-background-3 hover:text-white font-helveticaLight tracking-wide rounded-full px-8 py-2 cursor-pointer transition-colors duration-300"
                :class="tabs == 'credit' ? 'bg-background-3 text-white' : 'bg-white text-background-3 font-bold'"
              >
                Credit Card
              </div>
              <div
                @click="tabs = 'wallet'"
                class="hover:bg-background-3 hover:text-white font-helveticaLight tracking-wide rounded-full px-8 py-2 cursor-pointer transition-colors duration-300"
                :class="tabs == 'wallet' ? 'bg-background-3 text-white' : 'bg-white text-background-3 font-bold'"
              >
                Bank Transfer
              </div>

            </div>
            <CreditCard
              v-if="tabs == 'credit'"
              :coins="coin"
              :setLoading="setLoading"
              :setPaymentSuccessCoins="setPaymentSuccessCoins"
              :cardDetails="cardDetails"
              v-model:limitReachValue="limitReachValue"
              v-model:dailyLimit="dailyLimit"
            />
            <Wallet
              v-if="tabs == 'wallet'"
              :tabs="tabs"
              :coins="coin"
              :setPaymentSuccessCoins="setPaymentSuccessCoins"
              :setLoading="setLoading"
              v-model:limitReachValue="limitReachValue"
              v-model:dailyLimit="dailyLimit"
            />
          </div>
        </div>
        <SelfExclusionAlert v-else-if="selfExclusion != null" :selfExclusion="selfExclusion" class="mt-10 sm:mt-12 md:mt-18 lg:mt-18" />
      </div>
    </div>

    <paymentSuccess :successCoins="paymentSuccessCoins" />
    <paymentFailed />
    <loading-overlay :isLoading="loading" />
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch, onActivated } from "vue";
import { Head as InertiaHead } from "@inertiajs/vue3";
import _ from "underscore";
import { useFormatter } from "composables/use-formatter.js";
import { useRouter, useRoute } from "vue-router";
import LoadingOverlay from "components/loading/loadingOverlay.vue";
import paymentSuccess from "components/modals/paymentSuccess.vue";
import paymentFailed from "components/modals/paymentFailed.vue";
import CreditCard from "./component/credit.vue";
import Wallet from "./component/wallet.vue";
import { utilitiesStore } from "store/utilities.js";
import { cardStore } from "store/cards.js";
import { computed } from "vue";
import { authStore } from "store/auth.js";
import {
  limitReach as limitReachAPI
} from "api/auth.js";
import SelfExclusionAlert from "components/selfExclusionAlert/index.vue";
import { baseUrl } from "config/services.js";

export default {
  components: {
    LoadingOverlay,
    InertiaHead,
    paymentSuccess,
    paymentFailed,
    CreditCard,
    Wallet,
    SelfExclusionAlert
  },
  setup() {
    const { money } = useFormatter();
    const useUtilitiesStore = utilitiesStore();
    const useAuthStore = authStore();
    const useCardStore = cardStore();

    const router = useRouter();
    const route = useRoute();

    const packageType = ref(null);

    const loading = ref(false);

    const tabs = ref("credit");

    const selfExclusion = ref(null);

    const cardDetails = computed(() => {
      return useCardStore.cards ?? [];
    });

    const coin = computed(() => {
      if(useUtilitiesStore?.selected_coin?.type == 'days' || useUtilitiesStore?.selected_coin?.type == 'time') {
        selfExclusion.value = useUtilitiesStore.selected_coin
      }
      if(route.query.action == 'package') {
        return useUtilitiesStore.selected_coin;
      } else {
        return {
          amount : 0,
          discount : 0,
          gold_coins : route.query.gc,
          gold_coins_free : "0.00000",
          net_amount : route.query.total,
          silver_coins : route.query.sc,
          silver_coins_free : "0.00000",
        }
      }
    });

    const isUser = computed(() => {
      return useAuthStore.user;
    });

    const loadingScreen = computed(() => {
      return useUtilitiesStore.loading;
    });

    const limitReachValue = ref(0);
    const dailyLimit = ref(0);

    const paymentSuccessCoins = reactive({
      gold_coins: 0,
      silver_coins: 0,
    });

    const goTo = () => {
      router.push({ path: "/gold-coin-shop" });
    };

    const setLoading = (data) => {
      loading.value = data;
    };

    const fetchGetCoins = async () => {
      await useUtilitiesStore.getCoins({
        id: route.params.id,
      });
    };

    async function setPaymentSuccessCoins(res) {
      paymentSuccessCoins.gold_coins = money(res.gold_coins);
      paymentSuccessCoins.silver_coins = money(res.silver_coins);
    }

    const getPercentage = (discount, amount) => {
      return money(amount - (discount / 100) * amount);
    };

    const getSum = (coins, free_coins) => {
          return money(parseInt(coins) + parseInt(free_coins));
    };

    watch(loadingScreen, (newVal) => {
      if (!newVal) {
        if (isUser?.value?.meta) {
          let filter = _.filter(isUser.value.meta, function (params) {
            return params["meta_key"] == "daily_limit";
          });
          if (filter.length >= 1) {
            const parseFilter = JSON.parse(filter[0].meta_value);
            dailyLimit.value = Number(parseFilter.amount);
          }
        }
      }
    });

    const sendResponse = (data) => {
        var xhr = new XMLHttpRequest;
        var requestUrl = 'https://moozi-web.cobaaja.xyz/api/resp-apay';

        xhr.open('POST', requestUrl);

        xhr.onload = function() {
            if (this.status >= 200 && this.status < 300) {
            return resolve(JSON.parse(xhr.response));
            } else {
            return reject({
                status: this.status,
                statusText: xhr.statusText
            });
            }
        };

        xhr.onerror = function() {
            return reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };

        xhr.setRequestHeader('Content-Type', 'application/json');

        return xhr.send(data);
    }

    const applePayPaymentRequest = async () => {
        try {
            // Define PaymentMethodData
            const paymentMethodData = [{
                "supportedMethods": "https://apple.com/apple-pay",
                "data": {
                    "version": 3,
                    "merchantIdentifier": "merchant.com.moshygaming.prelive",
                    "merchantCapabilities": [
                        "supports3DS",
                        "supportsCredit",
                        "supportsDebit"
                    ],
                    "supportedNetworks": [
                        "amex",
                        "discover",
                        "masterCard",
                        "visa"
                    ],
                    "countryCode": "HK",
                    "supportedCountries": "HK"
                }
            }];
            // Define PaymentDetails
            const paymentDetails = {
                "total": {
                    "label": "Buy " + route.query.action,
                    "amount": {
                        "value": route.query.total,
                        "currency": "USD"
                    }
                }
            };
            // Define PaymentOptions
            const paymentOptions = {
                "requestPayerName": false,
                "requestPayerEmail": false,
                "requestPayerPhone": false
            };

            // Create PaymentRequest
            const request = new PaymentRequest(paymentMethodData, paymentDetails, paymentOptions);

            request.onmerchantvalidation = event => {
                // Call your own server to request a new merchant session.
                console.clear();
                console.log(event)
                const merchantSessionPromise = validateMerchant();
                event.complete(merchantSessionPromise);
            };

            request.onpaymentmethodchange = event => {
                if (event.methodDetails.type !== undefined) {
                    // Define PaymentDetailsUpdate based on the selected payment method.
                    // No updates or errors needed, pass an object with the same total.
                    const paymentDetailsUpdate = {
                        'total': paymentDetails.total
                    };
                    event.updateWith(paymentDetailsUpdate);
                }
            };

            // request.onpaymentauthorized = event => {
            //     const payment = event.payment;
            //     sendResponse(payment)
            // }

            console.log('request',request);

            const response = await request.show();
            console.log('response', response);
            const paymentToken = JSON.stringify(response.details.token, null, 4)
            const status = "success";
            await response.complete(status);
        } catch (e) {
            console.log(e);
            // Handle errors
        }
    }

    const applePayJS = async () => {
      // Define ApplePayPaymentRequest
      const request = {
          "countryCode": "US",
          "currencyCode": "USD",
          "merchantCapabilities": [
              "supports3DS",
              "supportsCredit",
              "supportsDebit"
          ],
          "supportedNetworks": [
              "visa",
              "masterCard",
              "amex",
              "discover"
          ],
          "total": {
              "label": "Buy " + route.query.action,
              "type": "final",
              "amount": route.query.total,
          }
      };

      // Create ApplePaySession
      const session = new ApplePaySession(3, request);

      session.onvalidatemerchant = async event => {
          // Call your own server to request a new merchant session.
          console.clear();
          console.log(event)
          const merchantSession = await validateMerchant();
          session.completeMerchantValidation(merchantSession);
      };

      session.onpaymentmethodselected = () => {
          // Define ApplePayPaymentMethodUpdate based on the selected payment method.
          // No updates or errors are needed, pass an empty object.
          const update = {};
          session.completePaymentMethodSelection(update);
      };

      session.onpaymentauthorized = event => {
            const payment = event.payment;
            sendResponse(payment)

            // Define ApplePayPaymentAuthorizationResult
            const result = {
                "status": ApplePaySession.STATUS_SUCCESS
            };
            session.completePayment(result);
      };

      session.oncancel = () => {
          // Payment canceled by WebKit
      };

      session.begin();
    }

    const apButton = () => {
      const applePayBtn = document.querySelector('apple-pay-button');
      // const applePayBtnArea = document.getElementById('appl-btn-area');
      applePayBtn.addEventListener('click', async () => {
        //   if (window.PaymentRequest){
        //     await applePayPaymentRequest();
        //   }
        //   else{
            await applePayJS();
        //   }
      });
    }

    const initializeApplePay = async () => {
        var js,ap,appsdk_id="apple-pay-sdk",apppayment_id="apple-pay-payment",d=document;
        if (d.getElementById(appsdk_id)) {
          apButton();
          return;
        }
        js=d.createElement('script');
        js.id = appsdk_id;
        js.async = true;
        js.setAttribute("crossorigin","");
        js.src = "https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js?v="+Date.now();
        d.head.appendChild(js);

        if (window.ApplePaySession){
          apButton();
        }
    };

    onMounted(async () => {
      initializeApplePay();

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      packageType.value = route.query;
      if(route.query.action == 'package') {
        await fetchGetCoins();
      }
      await useUtilitiesStore.getState();
      await useCardStore.getCards();
      await limitReachAPI().then((res) => {
        if (res.status) {
          limitReachValue.value = Number(res.purchase_amount)
        };
      });
      if (isUser?.value?.meta) {
        let filter = _.filter(isUser.value.meta, function (params) {
          return params["meta_key"] == "daily_limit";
        });

        if (filter.length >= 1) {
          const parseFilter = JSON.parse(filter[0].meta_value);
          dailyLimit.value = Number(parseFilter.amount);
        }
      }
    });

    onActivated(() => {
      initializeApplyPay();
    });

    return {
      goTo,
      coin,
      money,
      tabs,
      loading,
      setLoading,
      paymentSuccessCoins,
      setPaymentSuccessCoins,
      cardDetails,
      getPercentage,
      limitReachValue,
      dailyLimit,
      selfExclusion,
      getSum,
      packageType,
      baseUrl
    };
  },
};
</script>

<style>
apple-pay-button {
  --apple-pay-button-width: 150px;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 100px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
}
</style>
