<template>
     <div class="game-tab-wrapper h-full">
          <Carousel
               v-if="game_type != null"
               :itemsToShow="isUser ? (game_type.length + 4) : (game_type.length + 2)"
               snapAlign="start"
               :transition="500"
               :mouseDrag="false"
               :touchDrag="false"
               ref="gameTabsCarousel"
               class="game-tabs-carousel w-full h-full"
               :breakpoints="{
                    768: {
                         itemsToShow: 5,
                    },
                    1024: {
                         itemsToShow: 5,
                    },
                    1280: {
                         itemsToShow: 6,
                    },
                    1536: {
                         itemsToShow: 7,
                    },
               }"
          >
               <Slide key="0">
                    <div
                         @click="() => onSetActive('hot')"
                         class="w-full h-full flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover mr-1 px-2"
                         :class="active == 'hot' ? 'active' : ''"
                    >
                         <span class="icon-hot text-3xl"></span>
                         <p class="font-roboto font-extrabold text-white text-xs md:text-xs lg:text-sm xl:text-lg capitalize md:uppercase hidden md:block">HOT</p>
                    </div>
               </Slide>
               <Slide
                    v-if="isUser"
                    key="1"
               >
                    <div
                         @click="() => onSetActive('favorite')"
                         class="w-full h-full flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover mr-1 px-2"
                         :class="active == 'favorite' ? 'active' : ''"
                    >
                         <span class="icon-chevron-fav text-3xl"></span>
                         <p class="font-roboto font-extrabold text-white text-xs md:text-xs lg:text-sm xl:text-lg capitalize md:uppercase hidden md:block whitespace-nowrap">FAVORITES</p>
                    </div>
               </Slide>
               <Slide
                    v-if="isUser"
                    key="2"
               >
                    <div
                         @click="() => onSetActive('recent')"
                         class="w-full h-full flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover mr-1 px-2"
                         :class="active == 'recent' ? 'active' : ''"
                    >
                         <span class="icon-chevron-recent text-3xl"></span>
                         <p class="font-roboto font-extrabold text-white text-xs md:text-xs lg:text-sm xl:text-lg capitalize md:uppercase hidden md:block whitespace-nowrap">RECENT</p>
                    </div>
               </Slide>
               <Slide key="3">
                    <div
                         @click="() => onSetActive('all')"
                         class="w-full h-full flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover mr-1 px-2"
                         :class="active == 'all' ? 'active' : ''"
                    >
                         <span class="icon-allgames text-3xl"></span>
                         <p class="font-roboto font-extrabold text-white text-xxs md:text-xs lg:text-xs xl:text-base capitalize md:uppercase hidden md:block whitespace-nowrap">ALL GAMES</p>
                    </div>
               </Slide>
               <Slide
                    v-for="(item, key) in game_type"
                    :key="key + 4"
               >
                    <div
                         @click="() => onSetActive(item.game_type)"
                         class="w-full h-full flex flex-row gap-2 justify-center items-center cursor-pointer rounded-full gameTabHover mr-1 px-2"
                         :class="active == item.game_type ? 'active' : ''"
                    >
                         <span
                              class="text-3xl"
                              :class="'icon-'+item.game_type.replaceAll(' ','-')"
                         ></span>
                         <p 
                              class="font-roboto font-extrabold text-white md:uppercase hidden md:block uppercase whitespace-nowrap"
                              :class="item.game_type.length > 7 ? 'text-xxs md:text-xs lg:text-xs xl:text-base' : 'text-xs md:text-xs lg:text-sm xl:text-lg'"
                         >
                              {{item.game_type}}
                         </p>
                    </div>
               </Slide>
          </Carousel>
     </div>
     <div
          v-if="((game_type ? game_type.length : 0)  + (isUser ? 4 : 2)) > 7"
          @click="moreBtn"
          class="w-0 md:w-18 hidden md:flex h-full flex-row gap-2 justify-center items-center cursor-pointer"
     >
          <span
               class="icon-arrow-left text-3xl transition-transform duration-300"
               :class="isExpand ? '-rotate-180' : ''"
          ></span>
     </div>
</template>
<script>
import { ref } from "vue";
import { Carousel, Slide } from "vue3-carousel";
export default {
     props: ['game_type', 'isUser', 'onSetActive', 'active', 'isExpand', 'onExpand'],
     components: {
          Carousel,
          Slide,
     },
     setup(props) {
          const gameTabsCarousel = ref(null);

          const moreBtn = () => {
               if (!props.isExpand) {
                    if (props.isUser) {
                         props.onExpand(true)
                         gameTabsCarousel.value.slideTo(6);
                         gameTabsCarousel.value.data.currentSlide.value = 6;
                    } else {
                         props.onExpand(true)
                         gameTabsCarousel.value.slideTo(4);
                         gameTabsCarousel.value.data.currentSlide.value = 4;
                    }
               } else {
                    props.onExpand(false)
                    gameTabsCarousel.value.slideTo(0);
               }
          };

          return {
               gameTabsCarousel,
               moreBtn
          };
     },
};
</script>
