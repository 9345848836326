<template>
    <div class="mt-12 lg:mt-0 bg-white w-full lg:w-8/12 xl:w-9/12 px-5 md:px-7 lg:px-10 2xl:px-36 py-3 md:py-5 lg:py-10 flex flex-col justify-center items-center rounded-xl rounded-t-none lg:rounded-l-none lg:rounded-r-xl">
        <p class="text-shade-11 font-helveticaLight font-bold tracking-wide text-start sm:text-lg md:text-xl lg:text-lg xl:text-lg w-full mb-7">
            Transfer History
        </p>
        <div
            v-if="transaction_data && !transaction_data_loading"
            class="w-full px-3 py-2 bg-gray-90 rounded-md h-64 mb-3 overflow-auto"
            :class="transaction_data.length == 0 ? 'flex flex-row justify-center items-center h-36 mb-3' : ''"
        >
            <div v-if="transaction_data.length == 0">
                <p class="text-shade-7 font-helveticaLight tracking-wide text-start lg:text-center md:text-start font-normal text-sm sm:text-lg md:text-xl  lg:text-sm xl:text-base">
                        You haven't made any transfers yet.
                </p>
            </div>
            <table
                v-else
                class="border-separate border-spacing-y-2 table-auto w-full whitespace-nowrap"
            >
                <thead>
                        <tr>
                            <th class="text-left custom-flex-header text-xs xs:text-sm font-bold text-shade-7">
                                ID
                            </th>
                            <th class="text-left custom-flex-header text-xs xs:text-sm font-bold text-shade-7">
                                Date
                            </th>
                            <th class="text-left custom-flex-header text-xs xs:text-sm font-bold text-shade-7">
                                Status
                            </th>
                            <th class="text-left custom-flex-header text-xs xs:text-sm font-bold text-shade-7">
                                Transaction
                            </th>
                            <th class="text-right custom-flex-header text-xs xs:text-sm font-bold text-shade-7">
                                Amount
                            </th>
                        </tr>
                </thead>
                <tbody class="text-xs xs:text-sm">
                        <tr
                            v-for="(data, index) in transaction_data"
                            :key="index"
                            class="custom-flex-items border border-solid border-shade-10"
                        >
                            <td class="pr-5 md:pr-0">{{ data.id }}</td>
                            <td class="pr-5 md:pr-0">{{ date6(new Date(data.created_at)) }}</td>
                            <td class="pr-5 md:pr-0">
                                <div class="flex items-center">
                                    <div class="status-circle"></div>
                                    <span class="px-2 rounded-full text-white" :class="data.payment_status == 0
                                                ? 'bg-background-4'
                                                : data.payment_status == 1
                                                ? 'bg-shade-14'
                                                : 'bg-background-2'">
                                            {{
                                                data.payment_status == 0
                                                ? "Pending"
                                                : data.payment_status == 1
                                                ? "Success"
                                                : "Failed"
                                            }}
                                    </span>
                                </div>
                            </td>
                            <td class="pr-5 md:pr-0">
                                {{
                                    data.transaction_type == 1
                                    ? "Purchase"
                                    : data.transaction_type == 2
                                    ? "Withdraw"
                                    : "Bonus"
                                }}
                            </td>
                            <td class="pr-5 md:pr-0">
                                <div class="flex item-center justify-end font-bold text-right space-x-5">
                                    <div class="flex items-center justify-end space-x-0.5">
                                            <img
                                                :class="
                                                    data.transaction_type == 3
                                                    ? 'block h-4'
                                                    : 'hidden'
                                                "
                                                :src="baseUrl.img+'/assets/img/coin.png'"
                                                alt=""
                                            />
                                            <div>
                                                {{
                                                    data.transaction_type == 3
                                                    ? money(JSON.parse(data.conversion).gold_coins)
                                                    : "$" + money(data.net_amount)
                                                }}
                                            </div>
                                    </div>
                                    <div
                                            class="flex items-center justify-end space-x-0.5"
                                            :class="data.transaction_type == 3 ? '' : 'hidden'"
                                    >
                                            <img
                                                :class="
                                                    data.transaction_type == 3
                                                    ? 'block h-4'
                                                    : 'hidden'
                                                "
                                                :src="baseUrl.img+'/assets/img/coin-green.png'"
                                                alt=""
                                            />
                                            <div>
                                                {{
                                                    data.transaction_type == 3
                                                    ? money(JSON.parse(data.conversion).silver_coins)
                                                    : "$" + money(data.net_amount)
                                                }}
                                            </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                </tbody>
            </table>
        </div>
        <div
            v-else-if="transaction_data_loading"
            class="w-full px-3 py-2 bg-gray-90 rounded-md flex flex-row justify-center items-center h-36 mb-3 relative"
        >
            <loading-small :isLoading="transaction_data_loading" />
        </div>
        <div
            v-else
            class="w-full px-3 py-2 bg-gray-90 rounded-md flex flex-row justify-center items-center h-36 mb-3"
        >
            <p class="text-shade-7 font-helveticaLight tracking-wide text-start lg:text-center md:text-start font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base">
                You haven't made any transfers yet.
            </p>
        </div>
        <div v-if="transaction_data.length != 0" class="flex justify-end w-full">
            <p @click="goToTransaction" class="text-background-3 cursor-pointer font-helveticaLight tracking-wide font-normal text-sm sm:text-lg md:text-xl lg:text-sm xl:text-base underline underline-offset-2 mb-2">
                See More
            </p>
        </div>
    </div>
</template>
<script>
import { useFormatter } from "composables/use-formatter.js";
import { baseUrl } from "config/services.js";
export default {
    props: ['transaction_data', 'transaction_data_loading', 'goToTransaction'],
    setup() {
        const { date6, money } = useFormatter();

        return {
            date6, 
            money,
            baseUrl
        }
    },
}
</script>