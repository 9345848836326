import { utilitiesStore } from "store/utilities.js";
import { authStore } from "store/auth.js";
import { computed } from "vue";
import eventBus from "plugins/event.js";
import { useRouter } from "vue-router";
import _ from "underscore";

export const useKYC = () => {
    const router = useRouter();

    const useUtilitiesStore = utilitiesStore();
    const useAuthStore = authStore();

    const isUser = computed(() => {
        return useAuthStore.user;
    });

    const kycStatus = computed(() => {
        return useAuthStore.kycStatus;
    });

    const kycNotAllowed = computed(() => {
        return useAuthStore.kycNotAllowed;
    });

    const isEmailVerified = computed(() => {
        return useAuthStore.isEmailVerified;
    });

    const verifyNonDoc = () => {
        const find = _.find(isUser.value["kyc"], function (params) {
            return params.type == 'USA non-doc' && params.status == 1
        })

        return find;
    }

    const checkProcess = (callback, isNavigation = false) => {
        const {
            notAllowed = '',
            emailVerifiedCallback = () => { },
            addressCallback = () => { },
            findCallback = () => { },
            kycCallback = () => { },
            kycElseCallback = () => { },
            defaultCallback = () => { },
        } = callback;

        const find = isNavigation ? null : verifyNonDoc();

        // check if the user email is not verified
        if (!isEmailVerified.value) {
            eventBus.emit("open:verify_email");
            router.replace({ query: { modal: "email-verification" } });

            return emailVerifiedCallback();
        }
        // check if the user address is not set
        else if (!kycStatus.value && !isUser.value["address"]) {

            // check if the user usa non-doc is verified
            if (find) {
                return findCallback();
            }

            // if not kyc modal will appear
            eventBus.emit("open:kyc");
            router.replace({ query: { modal: "kyc" } });

            return addressCallback();
        }
        // check if the user address have been set and kyc not yet
        else if (!kycStatus.value && isUser.value["address"]) {

            // check if the user usa non-doc is verified
            if (find) {
                return findCallback();
            }

            // if not id verification modal will appear (subsum)
            eventBus.emit("open:idverification");
            router.replace({ query: { modal: "idverification" } });

            return kycCallback();
        }
        // if not meet other condition 
        else {
            // console.log('else');
            
            let shouldAllowed = false;

            if(notAllowed == 'gold-coin-shop') {
                shouldAllowed = true;
            }
            // let kycList = isUser.value.kyc;
            // if(kycList.length){
            //     let hasNonDoc = kycList.filter((item) => {
            //         return item.status === 1 && item.type == 'USA non-doc';
            //     });
            //     let basicKyc = kycList.filter((item) => {
            //         return item.status === 1 && item.type == 'basic-kyc-level';
            //     });
            //     if(hasNonDoc.length && basicKyc.length){
            //         shouldAllowed = 1;
            //     }
            // }

            // console.log('kycNotAllowed.value.includes(notAllowed)');
            // console.log(kycNotAllowed.value.includes(notAllowed));
            // console.log(kycNotAllowed.value);
            // console.log(notAllowed);
            
            // check if the current process if not allow to do 
            if (kycNotAllowed.value.includes(notAllowed)) {
                eventBus.emit("open:idverification");
                router.replace({ query: { modal: "idverification" } });

                return kycCallback();
            // } else if (isNavigation || shouldAllowed) {
            } else if (isNavigation || shouldAllowed) {
                // console.log('isNavigation');
                // check if the current process if allow to do then proceed
                kycElseCallback()
            }
    
        }

        // default catch
        if (!isNavigation) {
            return defaultCallback();
        }
    }

    const open_kyc_navigation = (is_from) => {

        if (is_from == "from_get_coins") {
            return checkProcess({
                notAllowed: "gold-coin-shop",
                emailVerifiedCallback: () => {
                    return true;
                },
                addressCallback: () => {
                    return true;
                },
                findCallback: () => {
                    useUtilitiesStore.enableScroll();
                    router.push({ path: "/gold-coin-shop" });
                    return false;
                },
                kycCallback: () => {
                    return true;
                },
                kycElseCallback: () => {
                    useUtilitiesStore.enableScroll();
                    router.push({ path: "/gold-coin-shop" });
                    return;
                },
                defaultCallback: () => {
                    
                    return false;
                }
            });
        } else if (is_from == "from_redeem") {
            checkProcess(
                {
                    notAllowed: "redeem",
                    emailVerifiedCallback: () => { },
                    addressCallback: () => { },
                    findCallback: () => { },
                    kycCallback: () => {
                        return true;
                    },
                    kycElseCallback: () => {
                        useUtilitiesStore.enableScroll();
                        router.push({ path: "/redeem" });
                    },
                    defaultCallback: () => { }
                },
                true
            );
        }
    };

    const open_kyc_game_launcher = () => {
        return checkProcess({
            notAllowed: "games_launch",
            emailVerifiedCallback: () => {
                return true;
            },
            addressCallback: () => {
                return true;
            },
            findCallback: () => {
                return false;
            },
            kycCallback: () => {
                return true;
            },
            kycElseCallback: () => {
                return false;
            },
            defaultCallback: () => {
                return false;
            }
        });
    };

    const open_kyc_referral = () => {
        return checkProcess({
            notAllowed: "referral_bonus",
            emailVerifiedCallback: () => {
                return {
                    status: true,
                    type: "Verify Email Address"
                };
            },
            addressCallback: () => {
                return {
                    status: true,
                    type: "Set Up Address"
                };
            },
            findCallback: () => {
                return {
                    status: false,
                    type: ""
                };
            },
            kycCallback: () => {
                return {
                    status: true,
                    type: "Set Up KYC"
                };
            },
            kycElseCallback: () => {
                return;
            },
            defaultCallback: () => {
                return {
                    status: false,
                    type: ""
                };
            }
        });
    };

    const open_kyc_bank = () => {
        return checkProcess({
            notAllowed: "add_bank",
            emailVerifiedCallback: () => {
                return true;
            },
            addressCallback: () => {
                return true;
            },
            findCallback: () => {
                return false;
            },
            kycCallback: () => {
                return true;
            },
            kycElseCallback: () => {
                return;
            },
            defaultCallback: () => {
                return false;
            }
        });
    };

    const open_kyc_get_coins = () => {
        return checkProcess({
            notAllowed: "gold-coin-shop",
            emailVerifiedCallback: () => {
                return true;
            },
            addressCallback: () => {
                return true;
            },
            findCallback: () => {
                return false;
            },
            kycCallback: () => {
                return true;
            },
            kycElseCallback: () => {
                return;
            },
            defaultCallback: () => {
                return false;
            }
        });
    };

    return {
        open_kyc_navigation,
        open_kyc_game_launcher,
        open_kyc_referral,
        open_kyc_bank,
        open_kyc_get_coins
    };
};
