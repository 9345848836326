import {
  games as gamesAPI,
  gameType as gameTypeAPI,
  providers as providersAPI,
  favorites as favoritesAPI,
  recent as recentAPI,
  popular as popularAPI,
  getHeart as getHeartAPI,
  allProviders as allProvidersAPI
 } from '@/api/games'
import { defineStore } from "pinia"
import _ from "underscore";

export const gamesStore = defineStore('games', {
  state: () => {
    return {
      games: null,
      allGames: null,
      providerGames: null,
      provider: null,
      favorites: null,
      allFavorites: null,
      providerFavorites: null,
      recent: null,
      allRecent: null,
      providerRecent: null,
      popular: null,
      groups: null,
      heart: null,
      gameHomeFilter: {
        page: 1,
        game_type: [],
        game_promo: 1,
        game_name: "",
        provider_id: [],
        active: "hot",
        sortType: "popularity",
      },
      gameFilter: {
        page: 1,
        game_type: [],
        game_promo: 1,
        game_name: "",
        provider_id: [],
        active: "hot",
        sortType: "popularity",
      },
      providerGameFilter: {
        page: 1,
        game_type: [],
        game_promo: 1,
        game_name: "",
        provider_id: [],
        active: "hot",
        sortType: "popularity",
      },
      favoriteSlider: null,
      recentSlider: null,
      allProvider: null,
      isActiveCoinType: null
    }
  },
  actions: {
    getGames(params, type = 'home', isReset = false) {
      //   if(this.games != null) {
      //     return;
      //   }

      return new Promise((resolve, reject) => {
        gamesAPI(params)
          .then(response => {
            const { games } = response;

            if (!games) {
              reject('Failed to load data!')
            }

            this.$patch((state) => {
              if (response?.status == false) {
                state.games = {
                  data: [],
                  total: 0,
                  current_page: 1,
                };
                state.groups = null
              } else {
                if (type == 'home') {
                  state.games = games;
                } else if (type == 'games') {
                  if (state.allGames == null) {
                    state.allGames = games;
                  } else {
                    let gamesLoaded = isReset ? [] : state.allGames.data;
                    gamesLoaded.push(...games.data)

                    if (params['game_type'].length != 0) {
                      gamesLoaded = _.filter(gamesLoaded, function (data) {
                        return params['game_type'].includes(data['game_type']);
                      })
                    }

                    state.allGames = games;
                    state.allGames['data'] = gamesLoaded;
                  }
                } else if (type == 'provider') {
                  if (state.providerGames == null) {
                    state.providerGames = games;
                  } else {
                    let gamesLoaded = isReset ? [] : state.providerGames.data;
                    gamesLoaded.push(...games.data)

                    if (params['game_type'].length != 0) {
                      gamesLoaded = _.filter(gamesLoaded, function (data) {
                        return params['game_type'].includes(data['game_type']);
                      })
                    }

                    state.providerGames = games;
                    state.providerGames['data'] = gamesLoaded;
                  }
                }
              }
            })
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              if (error.response) {
                if (type == 'home') {
                  state.games = error.response.data;
                } else if (type == 'games') {
                  state.allGames = error.response.data;
                } else if (type == 'provider') {
                  state.providerGames = error.response.data;
                }
              }
            });
            // errorToast('Error to load data!', "top-right");
            // console.error('Error to load games data!', error);
            reject(error)
          })
      })
    },
    getProvider() {
      if (this.provider != null) {
        return;
      }

      return new Promise((resolve, reject) => {
        providersAPI()
          .then(response => {
            const { provider, } = response;

            if (!provider) {
              reject('Failed to load data!')
            }

            this.$patch((state) => {
              state.provider = provider;
            })
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              if (error.response) {
                state.error_msg = error.response.data;
              }
            });
            // errorToast('Error to load data!', "top-right");
            // console.error('Error to load games data!', error);
            reject(error)
          })
      })
    },
    getFavorites(params, type = 'home', isReset = false) {
      return new Promise((resolve, reject) => {
        favoritesAPI(params)
          .then(response => {
            const { games } = response;

            if (!games) {
              reject('Failed to load data!')
            }

            this.$patch((state) => {
              if (type == 'home') {
                state.favorites = games;
              } else if (type == 'games') {
                if (state.allFavorites == null) {
                  state.allFavorites = games;
                } else {
                  let gamesLoaded = isReset ? [] : state.allFavorites.data;
                  gamesLoaded.push(...games.data)

                  if (params['game_type'].length != 0) {
                    gamesLoaded = _.filter(gamesLoaded, function (data) {
                      return params['game_type'].includes(data['game_type']);
                    })

                    // gamesLoaded = _.filter(gamesLoaded, function (data) {
                    //   return params['game_promo'] == data['game_promo'];
                    // })
                  }

                  state.allFavorites = games;
                  state.allFavorites['data'] = gamesLoaded;

                }
              } else if (type == 'provider') {
                if (state.providerFavorites == null) {
                  state.providerFavorites = games;
                } else {
                  let gamesLoaded = isReset ? [] : state.providerFavorites.data;
                  gamesLoaded.push(...games.data)

                  if (params['game_type'].length != 0) {
                    gamesLoaded = _.filter(gamesLoaded, function (data) {
                      return params['game_type'].includes(data['game_type']);
                    })
                  }

                  state.providerFavorites = games;
                  state.providerFavorites['data'] = gamesLoaded;

                }
              }
            })
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              if (error.response) {
                if (type == 'home') {
                  state.favorites = error.response.data;
                } else if (type == 'games') {
                  state.allFavorites = error.response.data;
                } else if (type == 'provider') {
                  state.providerFavorites = error.response.data;
                }
              }
            });
            // errorToast('Error to load data!', "top-right");
            // console.error('Error to load favorites data!', error);
            reject(error)
          })
      })
    },
    getRecent(params, type = 'home', isReset = false) {
      return new Promise((resolve, reject) => {
        recentAPI(params)
          .then(response => {
            const { games } = response;

            if (!games) {
              reject('Failed to load data!')
            }

            this.$patch((state) => {
              if (type == 'home') {
                state.recent = games;
              } else if (type == 'games') {
                if (state.allRecent == null) {
                  state.allRecent = games;
                } else {
                  let gamesLoaded = isReset ? [] : state.allRecent.data;
                  gamesLoaded.push(...games.data)

                  if (params['game_type'].length != 0) {
                    gamesLoaded = _.filter(gamesLoaded, function (data) {
                      return params['game_type'].includes(data['game_type']);
                    })

                    // gamesLoaded = _.filter(gamesLoaded, function (data) {
                    //   return params['game_promo'] == data['game_promo'];
                    // })
                  }

                  state.allRecent = games;
                  state.allRecent['data'] = gamesLoaded;

                }
              } else if (type == 'provider') {
                if (state.providerRecent == null) {
                  state.providerRecent = games;
                } else {
                  let gamesLoaded = isReset ? [] : state.providerRecent.data;
                  gamesLoaded.push(...games.data)

                  if (params['game_type'].length != 0) {
                    gamesLoaded = _.filter(gamesLoaded, function (data) {
                      return params['game_type'].includes(data['game_type']);
                    })

                    // gamesLoaded = _.filter(gamesLoaded, function (data) {
                    //   return params['game_promo'] == data['game_promo'];
                    // })
                  }

                  state.providerRecent = games;
                  state.providerRecent['data'] = gamesLoaded;

                }
              }
            })
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              if (error.response) {
                if (type == 'home') {
                  state.recent = error.response.data;
                } else if (type == 'games') {
                  state.allRecent = error.response.data;
                } else if (type == 'provider') {
                  state.providerRecent = error.response.data;
                }
              }
            });
            // errorToast('Error to load data!', "top-right");
            // console.error('Error to load recent data!', error);
            reject(error)
          })
      })
    },
    getPopular(data) {
      // if(this.popular != null) {
      //   return null;
      // }
      return new Promise((resolve, reject) => {
        popularAPI(data)
          .then(response => {

            if (!response) {
              reject('Failed to load data!')
            }

            this.$patch((state) => {
              state.popular = response;
            })
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              if (error.response) {
                state.error_msg = error.response.data;
              }
            });
            // errorToast('Error to load data!', "top-right");
            // console.error('Error to load recent data!', error);
            reject(error)
          })
      })
    },
    getGameType() {
      if (this.groups != null) {
        return;
      }

      return new Promise((resolve, reject) => {
        gameTypeAPI()
          .then(response => {
            const { groups, } = response;

            if (!groups) {
              reject('Failed to load data!')
            }

            this.$patch((state) => {
              state.groups = groups;
            })
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              if (error.response) {
                state.error_msg = error.response.data;
              }
            });
            // errorToast('Error to load data!', "top-right");
            // console.error('Error to load games data!', error);
            reject(error)
          })
      })
    },
    getHeart() {
      return new Promise((resolve, reject) => {
        getHeartAPI()
          .then(response => {
            const { games } = response;
            if (!games) {
              reject('Failed to load data!')
            }

            this.$patch((state) => {
              state.heart = games;
            })
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              if (error.response) {
                state.error_msg = error.response.data;
              }
            });
            // errorToast('Error to load data!', "top-right");
            // console.error('Error to load games data!', error);
            reject(error)
          })
      })
    },
    setGameHomeFilter(filter, active) {
      this.$patch((state) => {
        const obj = Object.keys(filter);
        const objFilter = Object.keys(state.gameHomeFilter);

        state.gameHomeFilter['active'] = active;

        for (let index = 0; index < obj.length; index++) {
          if(objFilter.includes(obj[index])){
            state.gameHomeFilter[obj[index]] = filter[obj[index]]
          }
        }

      })
    },
    setGameFilter(filter, active) {
      this.$patch((state) => {
        const obj = Object.keys(filter);
        const objFilter = Object.keys(state.gameFilter);

        state.gameFilter['active'] = active;

        for (let index = 0; index < obj.length; index++) {
          if(objFilter.includes(obj[index])){
            state.gameFilter[obj[index]] = filter[obj[index]]
          }
        }

      })
    },
    setProviderGameFilter(filter, active) {
      this.$patch((state) => {
        const obj = Object.keys(filter);
        const objFilter = Object.keys(state.providerGameFilter);

        state.providerGameFilter['active'] = active;

        for (let index = 0; index < obj.length; index++) {
          if(objFilter.includes(obj[index])){
            state.providerGameFilter[obj[index]] = filter[obj[index]]
          }
        }

      })
    },
    getFavoriteSlider(params, force = false) {
      if(!force) {
        if(this.favoriteSlider != null) {
          return;
        }
      }

      return new Promise((resolve, reject) => {
        favoritesAPI(params)
          .then(response => {
            const { games } = response;

            if (!games) {
              reject('Failed to load data!')
            }

            this.$patch((state) => {
              state.favoriteSlider = games;
            })
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              state.favoriteSlider = []
            });
            reject(error)
          })
      })
    },
    getRecentSlider(params) {
      return new Promise((resolve, reject) => {
        recentAPI(params)
          .then(response => {
            const { games } = response;

            if (!games) {
              reject('Failed to load data!')
            }

            this.$patch((state) => {
              state.recentSlider = games;
            })
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              state.recentSlider = []
            });
            reject(error)
          })
      })
    },
    getAllProvider() {
      if (this.allProvider != null) {
        return;
      }

      return new Promise((resolve, reject) => {
        allProvidersAPI()
          .then(response => {
            const { provider } = response;

            if (!provider) {
              reject('Failed to load data!')
            }

            this.$patch((state) => {
              const filter = _.filter(provider, function (params) {
                return params['icon'] != null && params['icon_mobile'] != null
              })
              state.allProvider = filter;
            })
            resolve()
          })
          .catch(error => {
            this.$patch((state) => {
              state.allProvider = []
            });
            reject(error)
          })
      })
    }
  },
})
